import { useState } from 'react';
import { useDIContext } from '@/Framework/DI/DIContext';
import { messageCodes } from '@/Framework/Message/messages';
import { AlertManager } from '@dealroadshow/uikit/core/components/Alert';
import { getMessage } from '@/Framework/Message/Mapper/getMessage';
import FilesystemRepository from '@/dataroom/infrastructure/repository/FilesystemRepository';
import { isFolder, splitItems } from '@/dataroom/domain/filesystem';
import { useDataroomContext } from '@/dataroom/application/DataroomContext';
import { useFolderTreeContext } from '@/dataroom/application/folderTree/FolderTreeContext';
import { useDataroomExplorerContext } from '@/dataroom/ui/common/DataroomExplorer/DataroomExplorerContext';
import DataroomErrorHandler, { getFailedItems } from '@/dataroom/application/ErrorHandler';
import { IFilesystemListItem } from '@/dataroom/domain/vo/collection/FilesystemListItem';
import { IFolderTree } from '@/dataroom/domain/vo/filesystem/FolderTree';

const useRemove = () => {
  const { container } = useDIContext();
  const [isFetching, setIsFetching] = useState(false);
  const { dataroom } = useDataroomContext();
  const { updateCollection: updateDataroomExplorerCollection } = useDataroomExplorerContext();
  const { updateFolderTree } = useFolderTreeContext();

  const remove = async (fileSystemItems: (IFilesystemListItem | IFolderTree)[]) => {
    setIsFetching(true);

    try {
      const payload = {
        dataroomId: dataroom.id,
        ...splitItems(fileSystemItems, ({ id }) => ({ id })),
      };

      await container.get<FilesystemRepository>(FilesystemRepository).remove(payload);

      AlertManager.success(
        getMessage(messageCodes.DATAROOM_REMOVE_FILESYSTEM_ITEMS_SUCCESS, {
          prefix: getSuccessNotificationPrefix(fileSystemItems),
        }),
      );

      updateDataroomExplorerCollection && updateDataroomExplorerCollection();
      updateFolderTree(fileSystemItems[0].id);
    } catch (error) {
      const { failedFileIds, failedFolderIds } = getFailedItems(error);

      if (failedFileIds || failedFolderIds) {
        const failedItems = fileSystemItems.filter((item) => (
          isFolder(item) ? failedFolderIds.includes(item.id) : failedFileIds.includes(item.id)
        ));

        AlertManager.error(
          getMessage(messageCodes.DATAROOM_REMOVE_FILESYSTEM_ITEMS_ERROR, {
            prefix: getSuccessNotificationPrefix(failedItems),
          }),
        );
      } else {
        container.get(DataroomErrorHandler).handleError(error);
      }
    } finally {
      setIsFetching(false);
    }
  };

  const getSuccessNotificationPrefix = (
    fileSystemItems: (IFilesystemListItem | IFolderTree)[],
  ): string => fileSystemItems.reduce((acc, item) => {
    const { name } = item;

    if (isFolder(item)) {
      acc.foldersCount++;
      acc.folders = (acc.folders || acc.files)
        ? `${ acc.foldersCount } folder${ acc.foldersCount > 1 ? 's' : '' }`
        : `"${ name }" has`;

      if (acc.files) {
        acc.files = `${ acc.filesCount } file${ acc.filesCount > 1 ? 's' : '' }`;
      }
    } else {
      acc.filesCount++;
      acc.files = (acc.files || acc.folders)
        ? `${ acc.filesCount } file${ acc.filesCount > 1 ? 's' : '' }`
        : `"${ name }" has`;

      if (acc.folders) {
        acc.folders = `${ acc.foldersCount } folder${ acc.foldersCount > 1 ? 's' : '' }`;
      }
    }

    const names = [acc.folders, acc.files].filter((item) => !!item).join(' and ');

    acc.result = `${ names }${ ((acc.folders && acc.files) || acc.foldersCount > 1 || acc.filesCount > 1) ? ' have' : '' }`;

    return acc;
  }, {
    folders: null,
    foldersCount: 0,
    files: null,
    filesCount: 0,
    result: '',
  }).result;

  return {
    isFetching,
    remove,
  };
};

export default useRemove;
