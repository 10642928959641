import React, { createContext, useContext } from 'react';
import Container from '@/Framework/DI/Container';

type TProps = {
  container: Container,
  children: any,
};

export const DIContext = createContext<{ container: Container }>(null);

export function useDIContext() {
  const context = useContext(DIContext);
  if (!context) {
    throw new Error('useDIContext must be used within the DIContextProvider');
  }
  return context;
}

function DIContextProvider({
  container,
  children,
}: TProps) {
  let contextValue: { container: Container } = {
    container,
  };

  return (
    <DIContext.Provider value={ contextValue }>
      { children }
    </DIContext.Provider>
  );
}

export default DIContextProvider;
