import React, { useState, createContext, useContext } from 'react';
import { useDIContext } from '@/Framework/DI/DIContext';
import { useDataroomContext } from '@/dataroom/application/DataroomContext';
import DataroomPcrRepository from '@/dataroom/infrastructure/repository/DataroomPcrRepository';
import DataroomErrorHandler from '@/dataroom/application/ErrorHandler';
import { IPcrEntitiesCount } from '@/dataroom/domain/vo/types/PcrEntitiesCount';

const initialPcrEntitiesCount = {
  total: 0,
  regulatoryEntityAccounts: 0,
  unassignedEntityAccounts: 0,
  contacts: 0,
};

const usePcr = () => {
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [pcrEntitiesCount, setPcrEntitiesCount] = useState<IPcrEntitiesCount>(initialPcrEntitiesCount);
  const [pcrAccountList, setPcrAccountList] = useState<string[]>([]);
  const pcrLimit = 150;

  const { container } = useDIContext();
  const { dataroom } = useDataroomContext();

  const assignPcrAccount = async (payload) => {
    try {
      setIsFetching(true);
      const dataroomPcrRepository = container.get<DataroomPcrRepository>(DataroomPcrRepository);
      await dataroomPcrRepository.assignPcrAccount(payload);
      setIsFetching(false);
    } catch (error) {
      setIsFetching(false);
      container.get(DataroomErrorHandler).handleError(error);
    }
  };

  const selfAssignPcrAccount = async (payload) => {
    try {
      setIsFetching(true);
      const dataroomPcrRepository = container.get<DataroomPcrRepository>(DataroomPcrRepository);
      await dataroomPcrRepository.selfAssignPcrAccount(payload);
      setIsFetching(false);
    } catch (error) {
      setIsFetching(false);
      container.get(DataroomErrorHandler).handleError(error);
    }
  };

  const getPcrEntitiesCount = async () => {
    try {
      setIsFetching(true);
      const dataroomPcrRepository = container.get<DataroomPcrRepository>(DataroomPcrRepository);
      const pcrEntitiesCount = await dataroomPcrRepository.getPcrEntitiesCount({ dataroomId: dataroom.id });
      setPcrEntitiesCount(pcrEntitiesCount);
      setIsFetching(false);
    } catch (error) {
      setIsFetching(false);
      container.get(DataroomErrorHandler).handleError(error);
    }
  };

  const getPcrAccountList = async () => {
    try {
      setIsFetching(true);
      const dataroomPcrRepository = container.get<DataroomPcrRepository>(DataroomPcrRepository);
      const pcrAccountList = await dataroomPcrRepository.getPcrAccountList({ dataroomId: dataroom.id });
      setIsFetching(false);
      setPcrAccountList(Object.values(pcrAccountList));
    } catch (error) {
      setIsFetching(false);
      container.get(DataroomErrorHandler).handleError(error);
    }
  };

  return {
    isFetching,
    pcrLimit,
    pcrEntitiesCount,
    pcrAccountList,
    getPcrEntitiesCount,
    getPcrAccountList,
    assignPcrAccount,
    selfAssignPcrAccount,
  };
};

type PcrContextType = ReturnType<typeof usePcr>;

export const PcrContext = createContext<PcrContextType>(null);

export function usePcrContext() {
  const context = useContext(PcrContext);
  if (!context) {
    throw new Error('usePcrContext must be used within a PcrContextProvider');
  }
  return context;
}

interface IProps {
  children: React.ReactNode,
}

function PcrContextProvider({ children }: IProps) {
  return (
    <PcrContext.Provider value={ usePcr() }>
      { children }
    </PcrContext.Provider>
  );
}

export default PcrContextProvider;
