/**
 * This filter is created to mute chunk loading errors. This is connected to issues with connection on user side.
 * - https://finsight.myjetbrains.com/youtrack/issue/FIN-12686
 * - https://sentry.io/organizations/finsight-group-inc/issues/2729428040/?project=5192325
 *
 * @return {boolean}
 */
function hasUnhandledChunkLoadError(hint) {
  return hint.originalException?.name === 'ChunkLoadError' && hint.originalException?.type === 'error';
}

export default hasUnhandledChunkLoadError;
