import React, { createContext, useContext } from 'react';
import { DndContext, useSensor, useSensors, pointerWithin } from '@dnd-kit/core';
import type { DragEndEvent } from '@dnd-kit/core/dist/types';
import DragOverlayComponent from '@/dataroom/ui/common/DragOverlayComponent';
import useDragAndDropMove from './useDragAndDropMove';
import DragAndDropSensor from './DragAndDropSensor';

const useDragAndDrop = () => {
  return {
    move: useDragAndDropMove()
  };
};

type TDragAndDropContext = ReturnType<typeof useDragAndDrop>;

export const DragAndDropContext = createContext<TDragAndDropContext>(null);

export const useDragAndDropContext = () => {
  const context = useContext(DragAndDropContext);
  if (!context) {
    throw new Error('useDragAndDropContext must be used within a DragAndDropContextProvider');
  }
  return context;
};

interface IProps {
  children: React.ReactNode,
}

const DragAndDropContextProvider = ({ children } : IProps) => {
  const mouseSensor = useSensor(DragAndDropSensor, {
    activationConstraint: {
      distance: 5,
    },
  });

  const sensors = useSensors(
    mouseSensor,
  );

  const contextValue = useDragAndDrop();

  const onDragStart = () => {
    contextValue.move.handleDragStart();
  };

  const onDragEnd = (event: DragEndEvent) => {
    contextValue.move.handleDragEnd(event);
  };

  return (
    <DragAndDropContext.Provider value={ contextValue }>
      <DndContext
        sensors={ sensors }
        onDragStart={ onDragStart }
        onDragEnd={ onDragEnd }
        collisionDetection={ pointerWithin }
      >
        { children }
        <DragOverlayComponent />
        { contextValue.move.modal }
      </DndContext>
    </DragAndDropContext.Provider>
  );
};

export default DragAndDropContextProvider;
