import React, { useState } from 'react';
import type { DragEndEvent } from '@dnd-kit/core/dist/types';
import { canSelectedDrop } from '@/dataroom/domain/filesystemPermissions';
import { useFilesystemContext } from '@/dataroom/application/filesystem/filesystemActions/FilesystemContext';
import DragAndDropMoveModal from '@/dataroom/ui/common/DataroomExplorer/Modals/DragAndDropModal';
import { IFilesystemListItem } from '@/dataroom/domain/vo/collection/FilesystemListItem';
import { IFolderTree } from '@/dataroom/domain/vo/filesystem/FolderTree';

type TItem = IFilesystemListItem | IFolderTree;

const useDragAndDropMove = () => {
  const [isDragAndDropMoveModalVisible, setIsDragAndDropMoveModalVisible] = useState<boolean>(false);
  const [items, setItems] = useState<TItem[]>(null);
  const [destination, setDestination] = useState<TItem>(null);

  const { move: { reset } } = useFilesystemContext();

  const showModal = (items: TItem[], destination: TItem) => {
    reset();
    setItems(items);
    setDestination(destination);
    setIsDragAndDropMoveModalVisible(true);
  };

  const hideModal = () => {
    setIsDragAndDropMoveModalVisible(false);
    setItems(null);
    setDestination(null);
  };

  const handleDragStart = () => {
    hideModal();
  };

  const handleDragEnd = ({ over, active }: DragEndEvent) => {
    if (!over) {
      return;
    }

    const items = active.data.current as TItem[];
    const destination = over.data.current as TItem;

    if (canSelectedDrop(items, destination)) {
      showModal(items, destination);
    }
  };

  return {
    handleDragStart,
    handleDragEnd,
    modal: isDragAndDropMoveModalVisible ? (
      <DragAndDropMoveModal
        filesystemItems={ items }
        closeModal={ hideModal }
        destinationFolder={ destination }
      />
    ) : null
  };
};

export default useDragAndDropMove;
