import * as Sentry from '@sentry/nextjs';
import config from '@/Framework/config';
import scrubSensitiveData from '@/Framework/browser/log/sentry/scrubSensitiveData';
import mustBeFiltered from './filter';
import Logger from '@/Framework/browser/log/Logger';

export default function createSentryConfig({ entry }: { entry: string, server?: boolean }) {
  try {
    Sentry.init({
      ...config.log.sentry,
      dist: entry,
      ignoreErrors: [
        'BrowserAbortError', // Custom Errors thrown in json-rpc-dispatcher when inflight requests are canceled by browser
      ],
      integrations: [
        Sentry.rewriteFramesIntegration({
          iteratee(frame) {
            frame.filename = frame.filename
              .replace(/^.*_next/, '~') // Fixing browser traces
              .replace(/^.*\.next/, '~') // Fixing server traces
              .replace(/^.*\/node_modules/, '~/node_modules'); // Fixing node_modules traces

            return frame;
          },
        }),
      ],
      initialScope: (scope) => {
        scope.setTags({ entry });
        return scope;
      },
      beforeBreadcrumb(breadcrumb) {
        // Console logs and uris may include private data like entry codes and hashes, so we disable them
        if (breadcrumb.category === 'console' || breadcrumb.category === 'navigation') {
          return null;
        }

        return breadcrumb;
      },
      beforeSend: (event, hint) => {
        event.extra = event.extra || {};

        // For some reason sentry serializes objects to 2 levels deep. But we need to go further
        if (!(hint.originalException instanceof Error)) {
          event.extra.__serialized__ = hint.originalException;
        }

        if (mustBeFiltered(event, hint)) {
          return null;
        }

        // @ts-ignore
        return scrubSensitiveData(event, hint);
      },
    });
  } catch (e) {
    // eslint-disable-next-line no-console
    Logger.warn(e);
  }
}
