import React from 'react';
import DataroomTenantContext from '@/dataroom/application/DataroomTenantContext';
import DataroomContext from '@/dataroom/application/DataroomContext';
import AccessRestrictedContext from '@/dataroom/application/AccessRestrictedContext';
import FileUploaderContext from '@/dataroom/application/FileUploader/FileUploaderContext';
import LoggerContext from '@/dataroom/application/LoggerContext';
import SidebarTabContext from '@/dataroom/application/SidebarTabContext';
import BookmarkingContext from '@/dataroom/application/BookmarkingContext';
import FolderInfoContext from '@/dataroom/application/FolderInfoContext';
import FileInfoContext from '@/dataroom/application/FileInfoContext';
import CurrentUserContext from '@/dataroom/application/CurrentUserContext';
import AccessRequestsCountContext from '@/dataroom/application/accessRequests/listing/AccessRequestsCountContext';
import FolderTreeContext from '@/dataroom/application/folderTree/FolderTreeContext';
import CurrentFolderContext from '@/dataroom/application/CurrentFolderContext';
import DataroomTwoFactorAuthenticationContext from '@dataroom/authentication';
import DataroomExplorerContext from '@/dataroom/ui/common/DataroomExplorer/DataroomExplorerContext';
import FilesystemContext from '@/dataroom/application/filesystem/filesystemActions/FilesystemContext';
import ExportToExcelContext from '@/dataroom/application/ExportToExcelContext';
import DownloadContext from '@/dataroom/application/DownloadContext';
import DialInsContext from '@/dataroom/application/DialInsContext';
import InfoPanelContext from '@/dataroom/application/InfoPanelContext';
import EmailUploadingFolderContext from '@/dataroom/application/EmailUploadingFolderContext';
import QuestionsExplorerContext from '@/dataroom/ui/common/Questions/QuestionsExplorer/QuestionsExplorerContext';
import DragAndDropContext from '@/dataroom/application/DragAndDrop/DragAndDropContext';
import UserActivityContext from '@/dataroom/application/UserActivityContext';
import PcrContext from '@/dataroom/application/PcrContext';
import DocumentPreviewContext from '@/dataroom/application/DocumentPreviewContext';
import DownloadArchiveStatusContext from '@/dataroom/application/DownloadArchiveStatusContext';
import DnDFileIndexesContext from '@/dataroom/application/DnDFileIndexesContext';
import { DataroomTenant } from '@/dataroom/domain/vo/types/DataroomTenant';
import BulkPinningContext from '@/dataroom/application/BulkPinningContext';
import RedactByKeywordContext from '@/dataroom/application/redaction/RedactByKeywordContext';
import RedactionTabCounterContext from '@/dataroom/application/redaction/RedactionTabCounterContext';

interface IProps {
  children: React.ReactNode,
  tenant: DataroomTenant,
}

function DataroomAppContext({
  children,
  tenant,
}: IProps) {
  return (
    <DataroomTenantContext tenant={ tenant }>
      <DataroomTwoFactorAuthenticationContext>
        <DataroomContext>
          <AccessRestrictedContext>
            <LoggerContext>
              <SidebarTabContext>
                <CurrentUserContext>
                  <FolderTreeContext>
                    <BookmarkingContext>
                      <FolderInfoContext>
                        <FileInfoContext>
                          <AccessRequestsCountContext>
                            <CurrentFolderContext>
                              <EmailUploadingFolderContext>
                                <DataroomExplorerContext>
                                  <ExportToExcelContext>
                                    <DownloadArchiveStatusContext>
                                      <DownloadContext>
                                        <DocumentPreviewContext>
                                          <FilesystemContext>
                                            <DialInsContext>
                                              <DnDFileIndexesContext>
                                                <FileUploaderContext>
                                                  <InfoPanelContext>
                                                    <QuestionsExplorerContext>
                                                      <UserActivityContext>
                                                        <PcrContext>
                                                          <DragAndDropContext>
                                                            <BulkPinningContext>
                                                              <RedactByKeywordContext>
                                                                <RedactionTabCounterContext>
                                                                  { children }
                                                                </RedactionTabCounterContext>
                                                              </RedactByKeywordContext>
                                                            </BulkPinningContext>
                                                          </DragAndDropContext>
                                                        </PcrContext>
                                                      </UserActivityContext>
                                                    </QuestionsExplorerContext>
                                                  </InfoPanelContext>
                                                </FileUploaderContext>
                                              </DnDFileIndexesContext>
                                            </DialInsContext>
                                          </FilesystemContext>
                                        </DocumentPreviewContext>
                                      </DownloadContext>
                                    </DownloadArchiveStatusContext>
                                  </ExportToExcelContext>
                                </DataroomExplorerContext>
                              </EmailUploadingFolderContext>
                            </CurrentFolderContext>
                          </AccessRequestsCountContext>
                        </FileInfoContext>
                      </FolderInfoContext>
                    </BookmarkingContext>
                  </FolderTreeContext>
                </CurrentUserContext>
              </SidebarTabContext>
            </LoggerContext>
          </AccessRestrictedContext>
        </DataroomContext>
      </DataroomTwoFactorAuthenticationContext>
    </DataroomTenantContext>
  );
}

export default DataroomAppContext;
