import React, { useState } from 'react';
import cn from 'classnames';
import isEmpty from 'lodash/isEmpty';
import useDisableModal from '@/dataroom/ui/common/DataroomExplorer/Modals/DisableModal/useDisableModal';
import useDeleteModal from '@/dataroom/ui/common/DataroomExplorer/Modals/DeleteModal/useDeleteModal';
import DeleteFileSystemItemsForm
  from '@/dataroom/ui/common/DataroomExplorer/Modals/DeleteModal/DeleteFileSystemItemsForm';
import useEditModal from '@/dataroom/ui/common/DataroomExplorer/Modals/EditModal/useEditModal';
import { contextMenuId } from './constants';
import * as filesystemPermissions from '@/dataroom/domain/filesystemPermissions';
import * as managePermissions from '@/dataroom/domain/managePermissions';
import * as questionPermissions from '@/dataroom/domain/questionPermissions';
import { isFileIndexingEnabled, isFilesystemPreparingForArchive, isUrl } from '@/dataroom/domain/filesystem';
import { Area } from '@/dataroom/domain/vo/Area';
import PortalWrp from '@/Framework/UI/Templates/PortalWrp';
import useAskQuestionModal from '@/dataroom/ui/components/Dataroom/components/AskQuestionModal/useAskQuestionModal';
import useCopyModal from '@/dataroom/ui/common/DataroomExplorer/Modals/CopyMoveModal/useCopyModal';
import useMoveModal from '@/dataroom/ui/common/DataroomExplorer/Modals/CopyMoveModal/useMoveModal';
import useChangeIndexModal from '@/dataroom/ui/common/DataroomExplorer/Modals/ChangeIndexModal/useChangeIndexModal';
import useCreateFolderModal from '@/dataroom/ui/common/DataroomExplorer/Modals/CreateFolderModal/useCreateFolderModal';
import useCreateLinkModal from '@/dataroom/ui/common/DataroomExplorer/Modals/CreateLinkModal/useCreateLinkModal';
import usePhysicalCopyModal from '@/dataroom/ui/components/dmPortal/Modals/PhysicalCopyModal/usePhysicalCopyModal';
import { usePrimaryFolderTreeContext } from '@/dataroom/application/folderTree/PrimaryFolderTreeContext';
import { useStagingFolderTreeContext } from '@/dataroom/application/folderTree/StagingFolderTreeContext';
import { useCurrentUserContext } from '@/dataroom/application/CurrentUserContext';
import { useDataroomContext } from '@/dataroom/application/DataroomContext';
import PreviewButton from '@/dataroom/ui/common/DataroomExplorer/Columns/Actions/PreviewButton';
import BookmarkButton from '@/dataroom/ui/common/DataroomExplorer/Columns/Actions/BookmarkButton';
import CopyLinkButton from '@/dataroom/ui/common/DataroomExplorer/Columns/Actions/CopyLinkButton';
import DownloadButton from '@/dataroom/ui/common/Download/DownloadButton';
import { variantTypes } from '@/Framework/UI/Atoms/Button/ActionButton/constants';
import InfoButton from '@/dataroom/ui/common/DataroomExplorer/Actions/InfoButton';
import styles from './contextMenu.scss';
import { IFolderTree } from '@/dataroom/domain/vo/filesystem/FolderTree';
import { IFilesystemListItem } from '@/dataroom/domain/vo/collection/FilesystemListItem';
import { useDnDFileIndexesContext } from '@/dataroom/application/DnDFileIndexesContext';
import IconFolderAdd from '@dealroadshow/uikit/core/components/Icon/IconFolderAdd';
import IconLinkAdd from '@dealroadshow/uikit/core/components/Icon/IconLinkAdd';
import IconFileIndex from '@dealroadshow/uikit/core/components/Icon/IconFileIndex';
import IconDownload from '@dealroadshow/uikit/core/components/Icon/IconDownload';
import IconAsk from '@dealroadshow/uikit/core/components/Icon/IconAsk';
import IconCopy from '@dealroadshow/uikit/core/components/Icon/IconCopy';
import IconList from '@dealroadshow/uikit/core/components/Icon/IconList';
import IconPencil from '@dealroadshow/uikit/core/components/Icon/IconPencil';
import IconMove from '@dealroadshow/uikit/core/components/Icon/IconMove';
import IconTrash from '@dealroadshow/uikit/core/components/Icon/IconTrash';
import IconDisable from '@dealroadshow/uikit/core/components/Icon/IconDisable';
import {
  ContextMenu as ContextMenuContainer,
  ContextMenuItem,
} from '@dealroadshow/uikit/core/components/Menu/ContextMenu';
import useBulkPinningModal from '@/dataroom/ui/common/DataroomExplorer/Modals/BulkPinUnpinModal/useBulkPinningModal';
import { BulkPinningAction } from '@/dataroom/domain/vo/filesystem/BulkPinning';
import IconUnpin from '@dealroadshow/uikit/core/components/Icon/IconUnpin';
import IconPin from '@dealroadshow/uikit/core/components/Icon/IconPin';
import RedactFileButton from '@/dataroom/ui/common/DataroomExplorer/Actions/RedactFileButton';

interface IProps {
  id: string,
}

const ContextMenu = ({ id = contextMenuId }: IProps) => {
  const [menuItems, setMenuItems] = useState([]);

  const { currentUser } = useCurrentUserContext();
  const { dataroom } = useDataroomContext();
  const {
    turnOnDnDFileIndexesMode,
    isFileIndexesButtonDisabled,
  } = useDnDFileIndexesContext();

  const userCanBulkDownload = managePermissions.canUserBulkDownload(currentUser);
  const userHasSystemManageAccess = managePermissions.canManageSettings(currentUser);
  const userIsDefaultAdmin = managePermissions.isDefaultAdmin(currentUser);
  const isPreparingForArchive = isFilesystemPreparingForArchive();

  const {
    showDeleteModal,
    hideDeleteModal,
    deleteItems,
    DeleteModal,
  } = useDeleteModal();

  const {
    showAskQuestionModal,
    hideAskQuestionModal,
    selectedFile,
    isQuestionModalVisible,
    AskQuestionModal,
  } = useAskQuestionModal();

  const {
    showDisableModal,
    hideDisableModal,
    disableItems,
    DisableModal,
  } = useDisableModal();

  const {
    showEditModal,
    hideEditModal,
    filesystemEditItem,
    EditModal,
  } = useEditModal();

  const {
    showCopyModal,
    hideCopyModal,
    filesystemCopyItems,
    CopyModal,
  } = useCopyModal();

  const {
    showMoveModal,
    hideMoveModal,
    filesystemMoveItems,
    MoveModal,
  } = useMoveModal();

  const {
    showChangeIndexModal,
    hideChangeIndexModal,
    changeIndexItem,
    ChangeIndexModal,
  } = useChangeIndexModal();

  const {
    showCreateFolderModal,
    hideCreateFolderModal,
    parentFolder,
    CreateFolderModal,
  } = useCreateFolderModal();

  const {
    showCreateLinkModal,
    hideCreateLinkModal,
    isVisible: isCreateLinkModalVisible,
    CreateLinkModal,
  } = useCreateLinkModal();

  const {
    Modal: PhysicalCopyModal,
    dataroomItem: dataroomPhysicalCopyItem,
    showPhysicalCopyModal,
    hidePhysicalCopyModal,
  } = usePhysicalCopyModal();

  const { primaryFolderTree } = usePrimaryFolderTreeContext();
  const { stagingFolderTree } = useStagingFolderTreeContext();
  const {
    BulkPinningModal,
    modalState: bulkPinningModalState,
    showBulkPinningModal,
    hideBulkPinningModal,
  } = useBulkPinningModal();
  const getAllowedMenuActions = (item: IFolderTree | IFilesystemListItem = null,
    parentFolder, selected) => {
    const area = (item || parentFolder)?.isStaging ? Area.Staging : Area.Primary;
    const selectedItems = selected?.length ? selected : [item];
    const canPreview = filesystemPermissions.canPreview(item);
    const canCreateFolder = filesystemPermissions.canCreateFolder(item || parentFolder);
    const canCreateLink = filesystemPermissions.canCreateLink(!item && parentFolder);
    const canDownload = filesystemPermissions.canDownload(item, userCanBulkDownload, isPreparingForArchive, 0);
    const canCopyAndMove = filesystemPermissions.canCopyAndMove(
      item, [primaryFolderTree, stagingFolderTree], [],
    );
    const canChangeIndex = filesystemPermissions.canChangeIndex(
      item,
      [],
      isFileIndexingEnabled(dataroom, area),
      userHasSystemManageAccess,
    );
    const canChangeIndexes = managePermissions.canUserManageOwnSettings(currentUser)
      && isFileIndexingEnabled(dataroom, area)
      && !selected?.length && !item;
    const canEdit = filesystemPermissions.canEdit(item, 0);
    const canDelete = filesystemPermissions.canDelete(item);
    const canDisable = filesystemPermissions.canDisable([item], userHasSystemManageAccess);
    const canRedactFile = filesystemPermissions.canRedactFile(currentUser, item as IFilesystemListItem, 0);
    const canSubmitQuestion = dataroom.qnaModule && questionPermissions.canSubmitQuestion(currentUser);
    const isAllLimitsReached = currentUser.qnaIsLimitReached;
    const { canRequestPhysicalCopy } = dataroom;
    const areAllItemsPinned = selectedItems.length > 0 && selectedItems.every((item) => item?.isFileIndexCustom);

    const data = {
      item,
      parentFolder,
      selected,
    };

    return [
      {
        key: 'preview',
        title: (
          <PreviewButton
            variant={ variantTypes.textWithIcon }
            // @ts-ignore TODO fix, this is the place for possible error
            item={ item }
            dataTest="previewButton"
          />
        ),
        className: styles.actionButtonMenuItem,
        isAllowed: canPreview,
      },
      {
        key: 'createFolder',
        title: (
          <ContextMenuItem
            title="Create Folder"
            icon={ IconFolderAdd }
            className={ styles.contextMenuItem }
            dataTest="createFolder"
          />
        ),
        className: styles.actionButtonMenuItem,
        action: showCreateFolderModal,
        isAllowed: canCreateFolder,
      },
      {
        key: 'createLink',
        title: (
          <ContextMenuItem
            title="Create Link"
            icon={ IconLinkAdd }
            className={ styles.contextMenuItem }
            dataTest="createLink"
          />
        ),
        className: styles.actionButtonMenuItem,
        action: showCreateLinkModal,
        isAllowed: canCreateLink,
      },
      {
        key: 'changeIndexesButton',
        title: (
          <ContextMenuItem
            title="Change Index #"
            icon={ IconFileIndex }
            className={ cn(styles.contextMenuItem, { [styles.isDisabled]: isFileIndexesButtonDisabled }) }
            disabled={ isFileIndexesButtonDisabled }
            dataTest="changeIndexes"
          />
        ),
        className: styles.actionButtonMenuItem,
        action: isFileIndexesButtonDisabled ? null : () => turnOnDnDFileIndexesMode(parentFolder?.isStaging),
        isAllowed: canChangeIndexes,
      },
      {
        key: 'download',
        title: (
          <DownloadButton
            variant={ variantTypes.textWithIcon }
            selected={ selectedItems }
            areas={ [selectedItems[0]?.isStaging ? Area.Staging : Area.Primary] }
            dataTest="downloadButton"
          />
        ),
        className: styles.actionButtonMenuItem,
        isAllowed: canDownload,
      },
      {
        key: 'requestPhysicalCopy',
        title: (
          <ContextMenuItem
            title="Request Physical Copy"
            icon={ IconDownload }
            className={ styles.contextMenuItem }
            dataTest="requestPhysicalCopyButton"
          />
        ),
        className: styles.actionButtonMenuItem,
        action: () => showPhysicalCopyModal(dataroom),
        isAllowed: item && canRequestPhysicalCopy,
      },
      {
        key: 'question',
        title: (
          <ContextMenuItem
            title="Ask a Question"
            icon={ IconAsk }
            className={ cn(styles.contextMenuItem, { [styles.isDisabled]: isAllLimitsReached }) }
            disabled={ isAllLimitsReached }
            dataTest="question"
          />
        ),
        className: styles.actionButtonMenuItem,
        action: !isAllLimitsReached && showAskQuestionModal,
        isAllowed: item && !item.isStaging && !isUrl(item) && canSubmitQuestion,
      },
      {
        key: 'bookmark',
        title: (
          <BookmarkButton
            variant={ variantTypes.textWithIcon }
            // @ts-ignore TODO fix, this is the place for possible error
            item={ item }
            dataTest="bookmarkButton"
          />
        ),
        className: styles.actionButtonMenuItem,
        isAllowed: item && primaryFolderTree && (item.id !== primaryFolderTree.id),
      },
      {
        key: 'copyLink',
        title: (
          <CopyLinkButton
            item={ item }
            dataTest="copyLink"
            isMenuVisible={ !!id }
            className={ styles.copyLinkButton }
          />
        ),
        className: styles.actionButtonMenuItem,
        isAllowed: item,
      },
      {
        key: 'info',
        title: (
          <InfoButton
            item={ item || parentFolder }
            dataTest="infoButton"
            variant={ variantTypes.textWithIcon }
            className={ styles.infoButton }
            title="View Details"
          />
        ),
        className: styles.actionButtonMenuItem,
        isAllowed: true,
      },
      {
        key: 'infoSeparator',
        isAllowed: true,
      },
      {
        key: 'copy',
        title: (
          <ContextMenuItem
            title="Copy"
            icon={ IconCopy }
            className={ styles.contextMenuItem }
            dataTest="copy"
          />
        ),
        className: styles.actionButtonMenuItem,
        action: showCopyModal,
        isAllowed: canCopyAndMove,
      },
      {
        key: 'changeIndex',
        title: (
          <ContextMenuItem
            title="Change Index #"
            icon={ IconList }
            className={ styles.contextMenuItem }
            dataTest="changeIndex"
          />
        ),
        className: styles.actionButtonMenuItem,
        action: showChangeIndexModal,
        isAllowed: canChangeIndex,
      },
      {
        key: 'edit',
        title: (
          <ContextMenuItem
            title="Edit"
            icon={ IconPencil }
            className={ styles.contextMenuItem }
            dataTest="edit"
          />
        ),
        className: styles.actionButtonMenuItem,
        action: showEditModal,
        isAllowed: canEdit,
      },
      {
        key: 'bulkPinning',
        title: (
          <ContextMenuItem
            title={ areAllItemsPinned ? 'Unpin' : 'Pin' }
            icon={ areAllItemsPinned ? IconUnpin : IconPin }
            className={ styles.contextMenuItem }
            dataTest={ areAllItemsPinned ? 'unpin' : 'pin' }
          />
        ),
        className: styles.actionButtonMenuItem,
        action: () => showBulkPinningModal(selectedItems,
          areAllItemsPinned ? BulkPinningAction.UNPIN : BulkPinningAction.PIN),
        isAllowed: userIsDefaultAdmin && canChangeIndex && selectedItems.length > 0,
      },
      {
        key: 'move',
        title: (
          <ContextMenuItem
            title="Move"
            icon={ IconMove }
            className={ styles.contextMenuItem }
            dataTest="move"
          />
        ),
        className: styles.actionButtonMenuItem,
        action: showMoveModal,
        isAllowed: canCopyAndMove,
      },
      {
        key: 'redact',
        title: (
          <RedactFileButton
            variant={ variantTypes.textWithIcon }
            // @ts-ignore TODO fix, this is the place for possible error
            item={ item }
            className={ styles.redactButton }
          />
        ),
        className: styles.actionButtonMenuItem,
        isAllowed: canRedactFile,
      },
      {
        key: 'mainSeparator',
        isAllowed: canCopyAndMove || canChangeIndex || canEdit,
      },
      {
        key: 'delete',
        title: (
          <ContextMenuItem
            title="Delete"
            icon={ IconTrash }
            className={ cn(styles.contextMenuItem, styles.isWarning) }
            dataTest="delete"
          />
        ),
        className: styles.actionButtonMenuItem,
        action: showDeleteModal,
        isAllowed: canDelete,
      },
      {
        key: 'disable',
        title: (
          <ContextMenuItem
            title="Disable"
            icon={ IconDisable }
            className={ cn(styles.contextMenuItem, styles.isWarning) }
            dataTest="disable"
          />
        ),
        className: styles.actionButtonMenuItem,
        action: showDisableModal,
        isAllowed: canDisable,
      },
    ].map(
      (item) => ({
        ...item,
        data,
      }),
    )
      .filter(
        (action) => (action.isAllowed),
      );
  };

  const handleFilterMenuItems = (e) => {
    const {
      fileSystemItem,
      parentFolder,
      selected,
    } = e.detail.data;
    setMenuItems(
      getAllowedMenuActions(fileSystemItem, parentFolder, selected),
    );
  };

  const menu = menuItems.map(({
    key,
    className,
    action,
    data,
    title,
  }) => {
    if (title) {
      return (
        <div
          key={ key }
          className={ className }
          onClick={ () => {
            action && action(data.item || data.parentFolder);
          } }
        >
          { title }
        </div>
      );
    }
    return (
      <div
        key={ key }
        className={ styles.separator }
      />
    );
  });

  return (
    <>
      <DeleteModal
        items={ deleteItems }
        closeModal={ hideDeleteModal }
      >
        { (props) => (<DeleteFileSystemItemsForm { ...props } />) }
      </DeleteModal>
      <DisableModal
        items={ disableItems }
        closeModal={ hideDisableModal }
      />
      { isQuestionModalVisible && (
        <AskQuestionModal
          isVisible
          file={ selectedFile }
          closeModal={ hideAskQuestionModal }
        />
      ) }
      <EditModal
        item={ filesystemEditItem }
        closeModal={ hideEditModal }
      />
      <MoveModal
        items={ filesystemMoveItems }
        closeModal={ hideMoveModal }
      />
      <CopyModal
        items={ filesystemCopyItems }
        closeModal={ hideCopyModal }
      />
      <ChangeIndexModal
        item={ changeIndexItem }
        closeModal={ hideChangeIndexModal }
      />
      <CreateFolderModal
        parentFolder={ parentFolder }
        closeModal={ hideCreateFolderModal }
      />
      <CreateLinkModal
        isVisible={ isCreateLinkModalVisible }
        closeModal={ hideCreateLinkModal }
      />
      <PhysicalCopyModal
        dataroom={ dataroomPhysicalCopyItem }
        onClose={ hidePhysicalCopyModal }
      />
      { bulkPinningModalState.isVisible && (
        <BulkPinningModal
          mode={
            bulkPinningModalState.mode === BulkPinningAction.PIN
              ? BulkPinningAction.PIN
              : BulkPinningAction.UNPIN
          }
          items={ bulkPinningModalState.items }
          closeModal={ hideBulkPinningModal }
        />
      ) }
      { /* @ts-ignore */ }
      <PortalWrp>
        <ContextMenuContainer
          id={ id }
          className={ cn(styles.contextMenu, {
            [styles.hiddenContextMenu]: isEmpty(menuItems),
          }) }
          onShow={ handleFilterMenuItems }
        >
          { menu }
        </ContextMenuContainer>
      </PortalWrp>
    </>
  );
};

export default ContextMenu;
