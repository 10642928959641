import { useState, useCallback, useEffect } from 'react';
import usersUrl from '@/users/infrastructure/usersUrl';
import { SESSION_PROPAGATE_FORM_NAME } from '../constants';
import DmPortalUrl from '@/dmPortal/infrastructure/url/dmPortalUrl';

const usePropagation = () => {
  const [ssid, setSsid] = useState(null);
  const [callbackUrl, setCallbackUrl] = useState(null);
  const [shouldPropagate, setShouldPropagate] = useState(false);

  /**
   * Propagate session and perform all necessary redirects
   *
   * @param {String} ssid
   * @param {String} callbackUrl
   */
  const propagate = useCallback(
    ({
      ssid,
      callbackUrl: rawCallbackUrl = DmPortalUrl.getUrl(),
      SAMLRequest,
      RelayState,
    }: {
      ssid?: string,
      callbackUrl: string,
      SAMLRequest?: string,
      RelayState?: string,
    }) => {
      const callbackUrl = usersUrl.getSessionPropagateUrl(rawCallbackUrl, SAMLRequest, RelayState);
      if (!ssid) {
        window.location.href = callbackUrl;
      } else {
        setSsid(ssid);
        setCallbackUrl(callbackUrl);
        setShouldPropagate(true);
      }
    },
    [ssid, callbackUrl],
  );

  useEffect(
    () => {
      if (shouldPropagate) {
        document
          .getElementById(SESSION_PROPAGATE_FORM_NAME)
          // @ts-ignore
          ?.submit();
      }
    },
    [shouldPropagate],
  );

  return {
    ssid,
    callbackUrl,
    shouldPropagate,
    propagate,
  };
};

export default usePropagation;
