import React from 'react';
import Head from 'next/head';

const HealthCheckPage = ({ Component, pageProps }: { Component: React.ElementType, pageProps }) => (
  <>
    <Head>
      <title>Health check</title>
      <meta charSet="utf-8" />
      <meta name="robots" content="noindex, nofollow" />
    </Head>
    <Component { ...pageProps } />
  </>
);

export default HealthCheckPage;
