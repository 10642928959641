import { format } from 'date-fns-tz';
import getZonedUTCDate from '@/Framework/DateTime/getZonedUTCDate';
import { ITimezone } from '@/Framework/TimeZone/vo/Timezone';

export function getTimeZoneDisplayNameDate(timeZone: ITimezone) {
  const offset = format(getZonedUTCDate(timeZone.timeZone), 'XXX', { timeZone: timeZone.timeZone });
  return `(GMT${ offset }) ${ timeZone.name } (${ timeZone.abbr })`;
}

export function getTimeZoneById(timeZones: ITimezone[], id: string) {
  return timeZones.find((timeZone) => timeZone.id === id);
}

export function getDefaultTimeZone(timeZones: ITimezone[]) {
  return timeZones.find((timeZone) => !!timeZone.default);
}

export const guessTimeZone = (timeZones: ITimezone[]) => {
  return (
    timeZones.find((timeZone) => timeZone.timeZone === Intl.DateTimeFormat().resolvedOptions().timeZone) ??
    getDefaultTimeZone(timeZones)
  );
};

export interface ITimeZoneOption {
  value: string,
  label: string,
  timeZone: string,
}

export const getTimeZoneOption = (timeZone: ITimezone): ITimeZoneOption => ({
  value: timeZone.id,
  label: getTimeZoneDisplayNameDate(timeZone),
  timeZone: timeZone.timeZone,
});
