import { useState, createContext, useContext, ReactNode } from 'react';
import { useDIContext } from '@/Framework/DI/DIContext';
import RedactionRepository from '@/dataroom/infrastructure/repository/RedactionRepository';
import { useDataroomContext } from '@/dataroom/application/DataroomContext';
import { Area } from '@/dataroom/domain/vo/Area';
import DataroomErrorHandler from '@/dataroom/application/ErrorHandler';
import { IRedactionTabCountType } from '@/dataroom/domain/vo/redaction/RedactionTabCountType';

const useRedactionTabCounter = () => {
  const { container } = useDIContext();
  const redactionRepository = container.get<RedactionRepository>(RedactionRepository);
  const { dataroom } = useDataroomContext();
  const [tabCounter, setTabCounter] = useState<IRedactionTabCountType>();

  const getRedactionCounts = async (area: Area) => {
    const payload = {
      dataroomId: dataroom.id,
      filesystemArea: area,
    };

    try {
      const counter = await redactionRepository.getTabCounter(payload);
      setTabCounter(counter);
    } catch (error) {
      container.get(DataroomErrorHandler)
        .handleError(error);
    }
  };

  return {
    getRedactionCounts,
    tabCounter,
  };
};

export const RedactionTabCounterContext = createContext<ReturnType<typeof useRedactionTabCounter>>(null);

export const useRedactionTabCounterContext = () => {
  const context = useContext(RedactionTabCounterContext);
  if (!context) {
    throw new Error('useRedactionTabCounterContext must be used within a RedactionTabCounterContextProvider');
  }
  return context;
};

interface IProps {
  children: ReactNode,
}

const RedactionTabCounterContextProvider = ({ children }: IProps) => (
  <RedactionTabCounterContext.Provider value={ useRedactionTabCounter() }>
    { children }
  </RedactionTabCounterContext.Provider>
);

export default RedactionTabCounterContextProvider;
