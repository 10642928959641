import React, { createContext, useContext, useState, useRef } from 'react';
import { v4 as uuid } from 'uuid';
import ErrorCodeHelper from '@finsight/error-codes';
import DownloadRequest from '@/dataroom/application/models/DownloadRequest';
import DownloadRepository from '@/dataroom/infrastructure/repository/DownloadRepository';
import { useDataroomTenantContext } from '@/dataroom/application/DataroomTenantContext';
import { useDataroomContext } from '@/dataroom/application/DataroomContext';
import { useCurrentFolderContext } from '@/dataroom/application/CurrentFolderContext';
import { splitItems } from '@/dataroom/domain/filesystem';
import { messageCodes } from '@/Framework/Message/messages';
import { AlertManager } from '@dealroadshow/uikit/core/components/Alert';
import { useDIContext } from '@/Framework/DI/DIContext';
import getMessage from '@/Framework/Message/getMessage';
import downloadFile from '@/Framework/api/downloadFile';
import dataroomUrl from '@/dataroom/infrastructure/dataroomUrl';
import DataroomErrorHandler from '@/dataroom/application/ErrorHandler';
import { IFilesystemListItem } from '@/dataroom/domain/vo/collection/FilesystemListItem';
import { Area } from '@/dataroom/domain/vo/Area';
import { useDownloadArchiveStatusContext } from '@/dataroom/application/DownloadArchiveStatusContext';
import { EnabledRedactions } from '@/dataroom/domain/vo/redaction/EnabledRedactions';

interface IDownloadRequestProps extends Omit<ConstructorParameters<typeof DownloadRequest>[0],
  'uuid' |
  'hash' |
  'folders' |
  'files' |
  'notificationTimer'
> {
  items: IFilesystemListItem[],
  areas: Area[],
  notificationTimeout: number,
  showNotification: boolean,
  archiveName?: string,
  preventPreloadModal?: boolean,
}

/**
 * If download doesn't start after this ms amount, notification will be displayed.
 */
const DOWNLOAD_NOTIFICATION_TIMEOUT = 5000;

export enum DirectDownloadType {
  ExcelExport = 'basic-export-report',
  Archive = 'archive-build',
}

/**
 * For archive and files downloading in folder explorer, quick filters,
 * bulk downloading and dataroom deactivation.
 *
 * If it is needed to download data (e.g. in folders explorer): requestDownload(...params).then(download)
 * If it is needed only to make request to download data (e.g. in deactivate flow): requestDownload(...params)
 */
const useDownload = () => {
  const { container } = useDIContext();
  const { tenant } = useDataroomTenantContext();
  const { dataroom } = useDataroomContext();
  const { currentFolder } = useCurrentFolderContext();
  const { incrementArchiveCount } = useDownloadArchiveStatusContext();

  const [isDownloadProcessing, setIsDownloadProcessing] = useState<boolean>(false);
  const [runningDownloadRequests, setRunningDownloadRequests] = useState<DownloadRequest[]>([]);
  const [archiveName, setArchiveName] = useState('');
  const [isArchiveNameFetching, setArchiveNameFetching] = useState(false);
  const [isPreloadModalVisible, setPreloadModalVisible] = useState(false);
  const [requestDownloadProps, setRequestDownloadProps] = useState(null);
  const [inputArchiveName, setInputArchiveName] = useState('');

  const closePreloadModal = () => {
    setPreloadModalVisible(false);
    setRequestDownloadProps(null);
    setInputArchiveName(null);
    requestDownloadProps?.onFinish && requestDownloadProps.onFinish();
  };

  // It is used for immediately change of running downloads requests queue,
  // e.g. in one render cycle in websocket event handlers after download request
  const runningDownloadRequestsQueue = useRef<DownloadRequest[]>([]);

  /**
   * Sets notification timeout of download request.
   */
  const setNotificationTimer = (
    downloadRequest: DownloadRequest,
    notificationTimeout: number = DOWNLOAD_NOTIFICATION_TIMEOUT,
  ) => {
    const notificationMessageCode = downloadRequest.isSingleFileRequest
      ? messageCodes.DATAROOM_DOWNLOAD_FILE_MESSAGE
      : messageCodes.DATAROOM_CREATING_ARCHIVE_MESSAGE;

    clearTimeout(downloadRequest.notificationTimer);

    if (notificationTimeout === 0) {
      AlertManager.success(getMessage(notificationMessageCode));
      handleRequestComplete(downloadRequest);
      return;
    }

    downloadRequest.notificationTimer = setTimeout(() => {
      handleRequestComplete(downloadRequest);
    }, notificationTimeout);
  };

  /**
   * Clears notification timeout.
   */
  const clearNotificationTimer = (downloadRequest: DownloadRequest) => {
    clearTimeout(downloadRequest.notificationTimer);
    downloadRequest.notificationTimer = null;
  };

  /**
   * Handles download request complete (doesn't matter error or success).
   */
  const handleRequestComplete = (downloadRequest: DownloadRequest) => {
    setIsDownloadProcessing(false);
    clearNotificationTimer(downloadRequest);
    downloadRequest.onFinish();
  };

  /**
   * Adds download request.
   */
  const addDownloadRequest = (downloadRequest: DownloadRequest) => {
    runningDownloadRequestsQueue.current = [
      ...runningDownloadRequestsQueue.current.filter(
        ({ uuid }) => uuid !== downloadRequest.uuid,
      ),
      downloadRequest,
    ];
    updateDownloadRequests();
  };

  /**
   * Removes download request from the queue.
   */
  const removeDownloadRequest = (downloadRequest: DownloadRequest) => {
    runningDownloadRequestsQueue.current = runningDownloadRequestsQueue.current.filter(
      ({ uuid }) => uuid !== downloadRequest.uuid,
    );
    updateDownloadRequests();
  };

  /**
   * Updates download requests.
   */
  const updateDownloadRequests = () => {
    setRunningDownloadRequests([...runningDownloadRequestsQueue.current]);
  };

  /**
   * Makes download request.
   */
  const requestDownload = async ({
    items = [],
    areas = [],
    notificationTimeout = DOWNLOAD_NOTIFICATION_TIMEOUT,
    quickFilterName,
    filters,
    truncationMode,
    stagingTruncationMode = null,
    onFinish,
    onDownload,
    showNotification = true,
    archiveName,
    preventPreloadModal = true,
    redaction = EnabledRedactions.WithRedactions,
  }: Partial<IDownloadRequestProps>): Promise<DownloadRequest> => new Promise((resolve) => (async () => {
    const {
      folders,
      files,
    } = splitItems<{ id: number }>(items, ({ id }) => ({ id }));

    const isDataroomDownload = quickFilterName === 'create_bulk';
    const isAllDataroomArchiveDownload = !folders.length && !files.length;
    const isArchiveDownload = folders.length || files.length > 1;

    if (
      !preventPreloadModal &&
      !isPreloadModalVisible &&
      (isDataroomDownload || isAllDataroomArchiveDownload || isArchiveDownload)
    ) {
      setPreloadModalVisible(true);
      setRequestDownloadProps(
        {
          items,
          areas,
          notificationTimeout,
          quickFilterName,
          filters,
          truncationMode,
          redaction,
          ...(stagingTruncationMode ? { stagingTruncationMode } : null),
          onFinish,
          onDownload,
          showNotification,
          archiveName,
        },
      );
      return;
    }

    isPreloadModalVisible && closePreloadModal();
    setIsDownloadProcessing(true);

    const downloadRequest = new DownloadRequest({
      uuid: uuid(),
      folders,
      files,
      areas,
      quickFilterName,
      filters,
      redaction,
      truncationMode,
      ...(stagingTruncationMode ? { stagingTruncationMode } : null),
      onFinish,
      onDownload,
      isPreloadModalVisible: true,
    });

    addDownloadRequest(downloadRequest);

    if (downloadRequest.isSingleFileRequest) {
      resolve(downloadRequest);
      return;
    }

    try {
      const handlers = {
        onFinish: (hash: string) => {
          downloadRequest.hash = hash;
          incrementArchiveCount();
          resolve(downloadRequest);
        },
        onError: () => {
          AlertManager.error(getMessage(messageCodes.GENERAL_ERROR));
          handleRequestComplete(downloadRequest);
          removeDownloadRequest(downloadRequest);
        },
      };

      const downloadPayload = {
        uuid: downloadRequest.uuid,
        dataroomId: dataroom.id,
        filesystemArea: areas[0], // If used only for dataroom quick filters download
        ...(downloadRequest.isDownloadAllRequested ? {
          quickFilterName: `${ quickFilterName || 'create_folder' }`,
          filters,
          ...(currentFolder ? { folderId: currentFolder.id } : {}),
        } : {
          folders,
          files,
        }),
        ...(inputArchiveName ? { archiveName: inputArchiveName } : {}),
        ...handlers,
      };

      const dataroomDownloadPayload = {
        uuid: downloadRequest.uuid,
        dataroomId: dataroom.id,
        filesystemArea: areas[0], // TODO: Check if we need it at all for dataroom bulk download
        quickFilterName,
        ...(inputArchiveName ? { archiveName: inputArchiveName } : {}),
        ...handlers,
      };

      const downloadRepository = container.get<DownloadRepository>(DownloadRepository);

      await downloadRepository.requestDownload(isDataroomDownload ? dataroomDownloadPayload : downloadPayload);
      showNotification && setNotificationTimer(downloadRequest, notificationTimeout);
    } catch (error) {
      if (error.getCode?.() === ErrorCodeHelper.getCodeByName('DATAROOM_BUILD_ARCHIVE_MAX_SIZE_LIMIT_REACHED')) {
        AlertManager.error(getMessage(messageCodes.DATAROOM_BUILD_ARCHIVE_MAX_SIZE_LIMIT_REACHED, {
          maxSize: error.getData().maxSize,
        }));
      } else {
        container.get(DataroomErrorHandler)
          .handleError(error);
      }
      handleRequestComplete(downloadRequest);
      removeDownloadRequest(downloadRequest);
    }
  })());

  /**
   * Proceeds downloading of files and folders.
   */
  const download = (downloadRequest: DownloadRequest, withImmediatelyNotification: boolean = false) => (
    downloadRequest.isSingleFileRequest
      ? downloadSingleFile(downloadRequest, withImmediatelyNotification)
      : downloadArchive(downloadRequest, withImmediatelyNotification)
  );

  /**
   * Proceeds single file downloading.
   */
  const downloadSingleFile = async (downloadRequest: DownloadRequest, withImmediatelyNotification: boolean) => {
    try {
      const fileId = downloadRequest.files[0].id;

      await container.get<DownloadRepository>(DownloadRepository)
        .checkDownloadAccess({
          dataroomId: dataroom.id,
          fileId,
        });

      withImmediatelyNotification && setNotificationTimer(downloadRequest, 0);
      const url = downloadRequest.redaction === EnabledRedactions.WithoutRedactions
        ? dataroomUrl(tenant).getDownloadOriginalFileUrl(dataroom.name, fileId)
        : dataroomUrl(tenant).getDownloadFileUrl(dataroom.name, fileId);

      downloadFile(url);
      downloadRequest.onDownload();
    } catch (error) {
      container.get(DataroomErrorHandler)
        .handleError(error);
    } finally {
      handleRequestComplete(downloadRequest);
      removeDownloadRequest(downloadRequest);
    }
  };

  /**
   * Proceeds archive downloading.
   */
  const downloadArchive = async (downloadRequest: DownloadRequest, withImmediatelyNotification: boolean) => {
    withImmediatelyNotification && setNotificationTimer(downloadRequest, 0);

    const {
      redaction,
      truncationMode,
      stagingTruncationMode,
    } = downloadRequest;

    downloadRequest.uuid = uuid();

    const handlers = {
      onPendingManual: () => {
        handleRequestComplete(downloadRequest);
        downloadRequest.isEmailModalVisible = true;
        updateDownloadRequests();
      },
      onHasLongPath: () => {
        handleRequestComplete(downloadRequest);
        downloadRequest.isTruncationModalVisible = true;
        updateDownloadRequests();
      },
      onError: () => {
        handleRequestComplete(downloadRequest);
        removeDownloadRequest(downloadRequest);
        AlertManager.error(getMessage(messageCodes.GENERAL_ERROR));
      },
      onFinish: (url) => {
        downloadRequest.onDownload();
        handleRequestComplete(downloadRequest);
        removeDownloadRequest(downloadRequest);
        downloadFile(url);
      },
    };

    const payload = {
      uuid: downloadRequest.uuid,
      dataroomId: dataroom.id,
      hash: downloadRequest.hash,
      redaction,
      truncationMode,
      stagingTruncationMode,
      ...handlers,
    };

    try {
      await container.get<DownloadRepository>(DownloadRepository)
        .downloadArchive(payload);
    } catch (error) {
      container.get(DataroomErrorHandler)
        .handleError(error);
      handleRequestComplete(downloadRequest);
      removeDownloadRequest(downloadRequest);
    }
  };

  /**
   * Direct downloading by download hash.
   */
  const directDownloadByHash = async (type: DirectDownloadType, hash: string) => {
    try {
      const downloadRepository = container.get<DownloadRepository>(DownloadRepository);
      const payload = {
        dataroomId: dataroom.id,
        hash,
      };

      switch (type) {
        case DirectDownloadType.ExcelExport:
          await downloadRepository.isExportDirectDownloadAvailable(payload);
          downloadFile(dataroomUrl(tenant)
            .getDownloadExportByHashUrl(dataroom.name, hash));
          break;
        case DirectDownloadType.Archive:
        default:
          await downloadRepository.isArchiveDirectDownloadAvailable(payload);
          downloadFile(dataroomUrl(tenant)
            .getDownloadArchiveByHashUrl(dataroom.name, hash));
          break;
      }
    } catch (error) {
      container.get(DataroomErrorHandler)
        .handleError(error);
    }
  };

  /**
   * Generate default archive name.
   */

  const generateDefaultArchiveName = async () => {
    setArchiveNameFetching(true);
    try {
      const payload = { dataroomId: dataroom.id };
      const downloadRepository = container.get<DownloadRepository>(DownloadRepository);
      const response = await downloadRepository.generateDefaultArchiveName(payload);

      setArchiveName(response.name);
    } catch (error) {
      container.get(DataroomErrorHandler)
        .handleError(error);
    } finally {
      setArchiveNameFetching(false);
    }
  };

  return {
    isDownloadProcessing,
    runningDownloadRequests,
    addDownloadRequest,
    removeDownloadRequest,
    setNotificationTimer,
    requestDownload,
    download,
    directDownloadByHash,
    archiveName,
    isArchiveNameFetching,
    generateDefaultArchiveName,
    isPreloadModalVisible,
    closePreloadModal,
    requestDownloadProps,
    inputArchiveName,
    setInputArchiveName,
  };
};

type DownloadContextType = ReturnType<typeof useDownload>;

export const DownloadContext = createContext<DownloadContextType>(null);

export function useDownloadContext() {
  const context = useContext(DownloadContext);
  if (!context) {
    throw new Error('useDownloadContext must be used within a DownloadContextProvider');
  }
  return context;
}

interface IProps {
  children: React.ReactElement,
}

function DownloadContextProvider({ children }: IProps) {
  return (
    <DownloadContext.Provider value={ useDownload() }>
      { children }
    </DownloadContext.Provider>
  );
}

export default DownloadContextProvider;
