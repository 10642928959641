import env from '@framework/env/env';
import { stringHandler } from '@dealroadshow/data-scrubber-js/dist/handler/stringHandler';
import { emailHandler } from '@dealroadshow/data-scrubber-js/dist/handler/emailHandler';

const logConfig = {
  sentry: {
    dsn: env('SENTRY_DSN'),
    debug: false,
    environment: env('SENTRY_ENVIRONMENT'),
    tracesSampleRate: 0,
    attachStacktrace: true,
    release: env('RELEASE_VERSION'),
  },
  dataScrubber: env('DATA_SCRUBBER__CONFIG_BASE64'),
  routeScrubber: [
    ['/login/investor/complete_profile/e/:entryCode', { entryCode: stringHandler() }],
    ['/e/:entryCode/:slideNum?', { entryCode: stringHandler() }],
    ['/login/investor/e/:entryCode', { entryCode: stringHandler() }],
    ['/login/sso/:email', { email: emailHandler({ leaveLetters: 2, leaveDomain: true }) }],
    ['/forgot_password/:email?', { email: emailHandler({ leaveLetters: 2, leaveDomain: true }) }],
    ['/forgot_password_success/:email', { email: emailHandler({ leaveLetters: 2, leaveDomain: true }) }],
    ['/reset_password/:email/:hash', {
      email: emailHandler({ leaveLetters: 2, leaveDomain: true }),
      hash: stringHandler(),
    }],
    ['/register_success/:email', { email: emailHandler({ leaveLetters: 2, leaveDomain: true }) }],
    ['/register/:email', { email: emailHandler({ leaveLetters: 2, leaveDomain: true }) }],
    ['/complete_profile/:email/:activationCode', {
      email: emailHandler({ leaveLetters: 2, leaveDomain: true }),
      activationCode: stringHandler(),
    }],
    ['/complete_profile/sso/:email/:activationCode', {
      email: emailHandler({ leaveLetters: 2, leaveDomain: true }),
      activationCode: stringHandler(),
    }],
  ],
};

export default logConfig;
