import { createContext, useContext, ReactNode } from 'react';
import { useCheckFiles } from '@/dataroom/application/redaction/useCheckFiles';
import { useScanning } from '@/dataroom/application/redaction/useScanning';

const useRedactByKeyword = () => ({
  scanning: useScanning(),
  checkFiles: useCheckFiles(),
});

export const RedactByKeywordContext = createContext<ReturnType<typeof useRedactByKeyword>>(null);

export const useRedactByKeywordContext = () => {
  const context = useContext(RedactByKeywordContext);
  if (!context) {
    throw new Error('useRedactByKeywordContext must be used within a RedactByKeywordContextProvider');
  }
  return context;
};

interface IProps {
  children: ReactNode,
}

const RedactByKeywordContextProvider = ({ children }: IProps) => (
  <RedactByKeywordContext.Provider value={ useRedactByKeyword() }>
    { children }
  </RedactByKeywordContext.Provider>
);

export default RedactByKeywordContextProvider;
