import React, { useRef, useEffect, useCallback } from 'react';
import cn from 'classnames';
import { EnabledIndexingKeys } from '../../constants';
import { EnabledRedactions } from '@/dataroom/domain/vo/redaction/EnabledRedactions';
import Button, { ButtonVariantType } from '@dealroadshow/uikit/core/components/Button';
import Dropdown from '@dealroadshow/uikit/core/components/Menu/Dropdown';
import IconArrowLeftBold from '@dealroadshow/uikit/core/components/Icon/IconArrowLeftBold';
import { useCurrentUserContext } from '@/dataroom/application/CurrentUserContext';
import { useDataroomContext } from '@/dataroom/application/DataroomContext';
import * as filesystem from '@/dataroom/domain/filesystem';
import * as filesystemPermission from '@/dataroom/domain/filesystemPermissions';
import { Area } from '@/dataroom/domain/vo/Area';
import styles from './dropdown.scss';

interface IProps {
  handleDownload: ({ truncationMode, redaction }?: { truncationMode?: string, redaction?: EnabledRedactions }) => void,
  handleHide: () => void,
  handleShow: () => void,
  isOneFileSelected: boolean,
  isDisabled: boolean,
  isDropdownOpen: boolean,
  area?: Area,
}

interface IDownloadItem {
  text: string,
  dataTest: string,
  onClick: () => void,
  isDisabled?: boolean,
  className?: string,
}

export interface DropdownRef {
  hide: () => void,
  dropdown: HTMLDivElement,
}

const DropdownComponent = (
  {
    handleDownload,
    handleHide,
    handleShow,
    isOneFileSelected,
    isDisabled,
    isDropdownOpen,
    area,
  }: IProps) => {
  const dropdownRef = useRef<DropdownRef>(null);
  const { dataroom } = useDataroomContext();
  const { currentUser } = useCurrentUserContext();
  const isFileIndexingEnabled = filesystem.isFileIndexingEnabled(dataroom, area);
  const canDownloadOriginalFile = filesystemPermission.canDownloadOriginalFile(currentUser);

  const downloadItems: IDownloadItem[] = [
    ...(isFileIndexingEnabled ? [{
      text: 'Download Index + Names',
      dataTest: 'downloadButtonIndexNames',
      className: cn({ [styles.itemWithDivider]: !canDownloadOriginalFile }),
      onClick: handleDownload,
    }] : []),

    ...(canDownloadOriginalFile ? [{
      text: 'Download Without Redactions',
      dataTest: 'downloadButtonWithoutRedactions',
      className: cn({ [styles.itemWithDivider]: isFileIndexingEnabled }),
      onClick: () => handleDownload({ redaction: EnabledRedactions.WithoutRedactions }),
    }] : []),

    ...(isFileIndexingEnabled ? [
      {
        text: 'Download Index Only',
        dataTest: 'downloadButtonIndexOnly',
        onClick: () => handleDownload({ truncationMode: EnabledIndexingKeys.IndexingEnabledDeleteAllNames }),
        isDisabled: isOneFileSelected,
      },
      {
        text: 'Download Names Only',
        dataTest: 'downloadButtonNamesOnly',
        onClick: () => handleDownload({ truncationMode: EnabledIndexingKeys.IndexingEnabledByIndex }),
        isDisabled: isOneFileSelected,
      },
    ] : []),
  ];

  const handleClickOutside = useCallback((event) => {
    if (!dropdownRef.current?.dropdown.contains(event.target)) {
      dropdownRef.current?.hide();
    }
  }, []);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  });

  return (
    <Dropdown
      ref={ dropdownRef }
      data-test="downloadButtonDropdown"
      usePortal={ false }
      onClose={ handleHide }
      onOpen={ handleShow }
      className={ cn({ [styles.isDisabled]: isDisabled }, styles.dropdown) }
      disabled={ isDisabled }
      active={ isDropdownOpen }
      trigger={ (
        <div className={ styles.iconWrapper }>
          <IconArrowLeftBold
            className={ cn({ [styles.isOpen]: isDropdownOpen }, styles.icon) }
          />
        </div>
      ) }
      triggerClassName={ styles.dropdownTrigger }
      contentClassName={ styles.content }
    >
      { downloadItems.map(({
        isDisabled,
        text,
        dataTest,
        onClick,
        className,
      }) => (
        <Button
          type="button"
          variant={ ButtonVariantType.text }
          className={ cn({ [styles.isDisabled]: isDisabled }, styles.dropdownItem, className) }
          onClick={ onClick }
          title={ text }
          key={ text }
          dataTest={ dataTest }
          disabled={ isDisabled }
        />
      )) }
    </Dropdown>
  );
};

export default DropdownComponent;
