import { trace, context } from '@dealroadshow/jsbro/OTel/api';
import Container from '@/Framework/DI/Container';
import { IUseOTelTraceContext, OTelTraceContext } from '@/Framework/OTel/OTelTraceContext';

const tracer = trace.getTracer();

const OTelInstrument = (
  object: any,
  methodName: string,
  container: Container,
  nameCallback?: (...args: any) => string,
  optionsCallback: (...args: any) => object = () => ({}),
) => {
  const originalCall = object[methodName];
  object[methodName] = (...args: any) => {
    let currentSpan = trace.getSpan(context.active());

    if (!currentSpan) {
      try {
        currentSpan = container.get<IUseOTelTraceContext>(OTelTraceContext).span;
      } catch (e) {
        // No active span
      }
    }

    const ctx = trace.setSpan(context.active(), currentSpan);

    const objectName = object.constructor.name;

    return tracer.startActiveSpan(
      nameCallback ? nameCallback(...args) : `${ objectName }.${ methodName }`,
      optionsCallback(...args),
      ctx,
      async (span) => {
        try {
          const result = originalCall(...args);
          span.end();
          return result;
        } catch (e) {
          span.recordException({ message: e.message });
          throw e;
        } finally {
          span.end();
        }
      },
    );
  };
};

export default OTelInstrument;
