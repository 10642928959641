import ErrorCodeHelper from '@finsight/error-codes';
import { RpcError } from '@dealroadshow/json-rpc-dispatcher';
import { AlertManager } from '@dealroadshow/uikit/core/components/Alert';
import { getMessageByErrorCode } from '@/Framework/Message/Mapper/getMessage';

import { History } from '@/Framework/DI/Providers/History';
import { SessionService } from '@/users/application/Session/SessionService';
import Store from '@/Framework/DI/Providers/Redux/Store';

export default (container) => (response) => {
  if (!(response instanceof RpcError)) {
    return response;
  }

  const errorCode = response.getCode();
  const errorCodes = [
    ErrorCodeHelper.getCodeByName('SESSION_PERMISSION_DENIED'),
    ErrorCodeHelper.getCodeByName('SESSION_NOT_ADMIN'),
  ];

  if (errorCodes.includes(errorCode)) {
    const history = container.get(History);
    // we should take the first available route for user on condor
    const sidebarList = container.get(Store).getState().condor.sidebar.navigationItems;
    // one item of the navigation list is just label (prod_label)
    const isApplicationFirst = sidebarList[0].id === 'prod_label';
    const availableRoute = isApplicationFirst ? sidebarList[1].href : sidebarList[0].href;
    if (availableRoute) {
      history.push(availableRoute);
      AlertManager.error(getMessageByErrorCode(errorCode));
    } else {
      const sessionService = container.get(SessionService);
      sessionService.logout();
    }
  }

  return response;
};
