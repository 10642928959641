import hasUnhandledRpcError from '@/Framework/browser/log/sentry/filter/hasUnhandledRpcError';
import hasObjectNotFoundMatchingIdError from '@/Framework/browser/log/sentry/filter/hasObjectNotFoundMatchingIdError';
import hasUnhandledChunkLoadError from '@/Framework/browser/log/sentry/filter/hasUnhandledChunkLoadError';
import hasUnhandledIllegalInvocationInGtagError from '@/Framework/browser/log/sentry/filter/gtag/hasUnhandledIllegalInvocationInGtagError';
import hasGtagError from '@/Framework/browser/log/sentry/filter/gtag/hasGtagError';
import hasJsonpError from '@/Framework/browser/log/sentry/filter/hasJsonpError';
import hasExternalError from '@/Framework/browser/log/sentry/filter/hasExternalError';

export default function mustBeFiltered(event, hint) {
  return (
    hasUnhandledRpcError(event, hint) ||
    hasObjectNotFoundMatchingIdError(event) ||
    hasUnhandledChunkLoadError(hint) ||
    hasUnhandledIllegalInvocationInGtagError(event) ||
    hasGtagError(event) ||
    hasJsonpError(event) ||
    hasExternalError(event)
  );
}
