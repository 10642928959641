import { Event } from '@sentry/nextjs';
/**
 * This filter is created to mute JSONP polling errors: _jp[hash] is not a function.
 *
 * @param {Event} event Sentry.Event
 * @returns {boolean}
 */
function hasJsonpError(event: Event): boolean {
  const { type, value } = (event.exception?.values || [{}])[0];
  const typeMatches = type === 'TypeError';
  const valueMatches = /_jp\.\w+ is not a function/.test(value);

  return typeMatches && valueMatches;
}

export default hasJsonpError;
