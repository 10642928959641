import React, { createContext, useContext, useEffect, useState, useRef } from 'react';
import TimeTrackingRepository from '@/dataroom/infrastructure/repository/TimeTrackingRepository';
import trackIdleTrigger from '@/Framework/browser/timers/trackIdle';
import { useDIContext } from '@/Framework/DI/DIContext';
import { useDataroomContext } from '@/dataroom/application/DataroomContext';
import { useCurrentUserContext } from '@/dataroom/application/CurrentUserContext';
import { useDataroomTenantContext } from '@/dataroom/application/DataroomTenantContext';
import config from '@/dataroom/application/config/config';
import { useLoggerContext } from '@/dataroom/application/LoggerContext';

const TIMER_TIMEOUT = 2 * 60 * 1000; // 2 min

const useUserActivity = () => {
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const { dataroom } = useDataroomContext();
  const { tenant } = useDataroomTenantContext();
  const { currentUser } = useCurrentUserContext();
  const { logged } = useLoggerContext();
  const { container } = useDIContext();

  const timeTrackingRepository = container.get<TimeTrackingRepository>(TimeTrackingRepository);

  const pingTimeout = 1000 * dataroom?.pingTimeout;

  const setPingInterval = useRef<NodeJS.Timeout | number>(0);

  const initTimer = () => {
    if (!isSubscribed) {
      subscribeHandler();
    }
    trackIdleTrigger(
      TIMER_TIMEOUT,
      unsubscribeHandler,
      subscribeHandler,
    );
  };

  const pauseInactivityTimer = () => {
    setIsPaused(true);
  };
  const resumeInactivityTimer = () => {
    setIsPaused(false);
  };

  const subscribeHandler = () => {
    setIsSubscribed(true);

    timeTrackingRepository.subscribeToTimeTracking({
      dataroomId: dataroom.id,
      userId: currentUser.id,
    }).then(startPingInterval);
  };

  const unsubscribeHandler = () => {
    setIsSubscribed(false);

    if (!isPaused) {
      timeTrackingRepository.unsubscribeToTimeTracking();
      clearInterval(setPingInterval.current);
      setPingInterval.current = null;
    }
  };

  const startPingInterval = () => {
    if (!setPingInterval.current) {
      setPingInterval.current = setInterval(() => {
        timeTrackingRepository.sendSocketPing({
          dataroomId: dataroom.id,
          userId: currentUser.id,
        });
      }, pingTimeout);
    }
  };

  useEffect(() => {
    if (
      dataroom
      && currentUser
      && tenant === config.tenant.tenantDealvdr.code
      && logged
    ) {
      initTimer();
    }
  }, [dataroom?.id, currentUser?.id, logged]);

  return {
    pauseInactivityTimer,
    resumeInactivityTimer,
  };
};

export type UserActivityContextType = ReturnType<typeof useUserActivity>;

export const UserActivityContext = createContext<UserActivityContextType>(null);

export const useUserActivityContext = () => {
  const context = useContext(UserActivityContext);
  if (!context) {
    throw new Error('useUserActivityContext must be used within a UserActivityContext');
  }
  return context;
};

interface IProps {
  children: React.ReactNode,
}

const UserActivityContextProvider = ({ children }: IProps) => (
  <UserActivityContext.Provider value={ useUserActivity() }>
    { children }
  </UserActivityContext.Provider>
);

export default UserActivityContextProvider;
