import { useState } from 'react';
import { useDIContext } from '@/Framework/DI/DIContext';
import { useDataroomContext } from '@/dataroom/application/DataroomContext';
import DataroomErrorHandler from '@/dataroom/application/ErrorHandler';
import RedactionRepository from '@/dataroom/infrastructure/repository/RedactionRepository';
import { AlertManager } from '@dealroadshow/uikit/core/components/Alert';
import { messageCodes } from '@/Framework/Message/messages';
import { getMessage } from '@/Framework/Message/Mapper/getMessage';
import { getItemsPayload, Items } from '@/dataroom/application/redaction/helpers/getItemsPayload';

export const useScanning = () => {
  const { container } = useDIContext();
  const { dataroom } = useDataroomContext();

  const [isFetching, setIsFetching] = useState(false);

  const scanForReview = async (search: string, items: Items) => {
    setIsFetching(true);

    try {
      const payload = {
        dataroomId: dataroom.id,
        search,
        items: getItemsPayload(items),
      };

      const redactionRepository = container.get<RedactionRepository>(RedactionRepository);

      // TODO: need to remove this message after socket implementation
      AlertManager.success(getMessage(messageCodes.DATAROOM_REDACTION_SCAN_BY_KEYWORD_STARTED));
      await redactionRepository.scanByKeyword(payload);
    } catch (error) {
      container.get(DataroomErrorHandler)
        .handleError(error);
      throw error;
    } finally {
      setIsFetching(false);
    }
  };

  return {
    isFetching,
    scanForReview,
  };
};
