import { Dependencies } from 'constitute';
import Container from '@/Framework/DI/Container';
import Request from '@/Framework/api/Rpc/Request';
import { withCache } from '@/Framework/withCache';
import UsersRpcDispatcher from '@/users/application/DI/Rpc/HttpDispatcher';
import Rpc, { RpcSuccess } from '@dealroadshow/json-rpc-dispatcher';
import { DealroadshowTenant } from '@/Framework/Tenant/vo/types/DealroadshowTenant';
import { EvercallTenant } from '@/Framework/Tenant/vo/types/EvercallTenant';

export const INVESTOR_LOGIN_SSID_KEY = 'investor.login.ssid';

@Dependencies(Container)
class SessionRepository {
  private usersRpc: Rpc;

  constructor(private container: Container) {
    container.schedulePostConstructor(
      (usersRpcDispatcher) => {
        this.usersRpc = usersRpcDispatcher;
      }, [UsersRpcDispatcher]);
  }

  /**
   * Will set cookie, if no cookie provided will try to finish sso login looking for ssoSsid cookie (back-end managed)
   */
  setSsidCookie = async (ssid?: string) => {
    const req = new Request('session.set_ssid_cookie', { ...(ssid && { ssid }) }, { ...(ssid && { ssid }) });
    const response = await this.usersRpc.call<RpcSuccess>(req);

    return response.getResult();
  };

  /**
   * Create session for user
   *
   * @param {Object} data
   * @return {Promise.<*>}
   */
  async createUserSession(data) {
    let req = new Request('session.login', data);
    let response = await this.usersRpc.call<RpcSuccess>(req);

    // @ts-ignore
    return response.getResult().ssid;
  }

  /**
   * Create session for user with special params(by entry code, by dashboard id, etc)
   */
  createCustomLogin = async (
    payload: {
      email: string,
    } & (
      | {
          tenant: DealroadshowTenant,
          params: {
            entryCode: string,
            verificationToken?: string,
          },
        }
      | {
          tenant: EvercallTenant,
          params:
            | {
                dashboardId: string,
                token: string | null,
              }
            | {
                conferenceId: string,
              },
        }
    ),
  ): Promise<{ ssid: string }> => {
    const req = new Request('session.custom_login', payload);
    const response = await this.usersRpc.call<RpcSuccess>(req);

    return response.getResult();
  };

  /**
   * Verify session for user
   *
   * @return {Promise.<*>}
   */
  async verifySession() {
    let req = new Request('session.verify');
    let response = await this.usersRpc.call<RpcSuccess>(req);

    return response.getResult();
  }

  /**
   * Deletes session
   *
   * @returns {Promise<*>}
   */
  async invalidateSession() {
    const req = new Request('session.invalidate');

    const response = await this.usersRpc.call<RpcSuccess>(req);
    return response.getResult();
  }

  /**
   * Prolongs session
   *
   * @param {String} tenant
   *
   * @returns {Promise<*>}
   */
  async prolongSession({ tenant = undefined }) {
    let req = new Request('session.prolong', { tenant });
    let response = await this.usersRpc.call<RpcSuccess>(req);

    return response.getResult() as object;
  }

  /**
   * Get current session
   *
   * @param {?String} ssid
   * @param {CacheOptions} cacheOptions
   *
   * @return {Promise<Object>}
   */
  @withCache
  async getCurrentSession(ssid = null) {
    let req = new Request('session.get_current_session', null, { ...ssid && { ssid } });
    let response = await this.usersRpc.call<RpcSuccess>(req);

    return response.getResult();
  }

  /**
   * get current user from session
   *
   * @param {?String} ssid
   * @param {CacheOptions} cacheOptions
   *
   * @return {Promise<*>}
   */
  @withCache
  async getCurrentUser(ssid = null) {
    let req = new Request('session.get_current_user', { aggregateCompany: true }, { ...ssid && { ssid } });
    let response = await this.usersRpc.call<RpcSuccess>(req);

    return response.getResult();
  }
}

export default SessionRepository;
