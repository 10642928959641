import { v4 as uuid } from 'uuid';
import { Dependencies } from 'constitute';
import { RpcError, RpcSuccess } from '@dealroadshow/json-rpc-dispatcher';
import { Subscription } from '@dealroadshow/socket-frontend-sdk';
import Request from '@/Framework/api/Rpc/Request';
import JsonRpcDispatcherFactory from '@/dataroom/application/DI/Rpc/HttpDispatcher';
import SocketClientFactory from '@/dataroom/application/DI/Socket/Client';
import { IFilesystemActionWithConflictPayload } from '@/dataroom/domain/vo/filesystem/FilesystemActionWithConflictPayload';
import { IFilesystemConflictResolvingItem } from '@/dataroom/domain/vo/filesystem/FilesystemConflictResolvingItem';
import { IFilesystemItem } from '@/dataroom/domain/vo/filesystem/FilesystemItem';

interface IFilesystemActionSuccessResponse {
  successItems: IFilesystemConflictResolvingItem[],
  conflictItems: IFilesystemConflictResolvingItem[],
}

export interface IRenameResult {
  success: boolean,
  conflictItems: IFilesystemItem[],
  failedItems: IFilesystemItem[],
}

@Dependencies(JsonRpcDispatcherFactory, SocketClientFactory)
class FilesystemRepository {
  constructor(protected rpc: typeof JsonRpcDispatcherFactory, private socketClient: typeof SocketClientFactory) {
  }

  copy = async (payload: IFilesystemActionWithConflictPayload & {
    onFinish: (response: IFilesystemActionSuccessResponse) => void,
    onError: (error) => void,
  }): Promise<void> => {
    const {
      onFinish,
      onError,
      ...requestPayload
    } = payload;

    const requestUuid = uuid();

    const subscribeReq = new Request('dataroom.filesystem.copy.listen', {
      uuid: requestUuid,
    });

    const subscription = await this.socketClient().subscribe<Subscription>(subscribeReq);

    subscription
      .on('filesystem.copy.completed', (response) => {
        subscription.cancel();
        onFinish(response.params.payload);
      })
      .on('filesystem.copy.error', (error) => {
        subscription.cancel();
        error.getData = () => ([error.params.payload]);
        onError(error);
      });

    const request = new Request('dataroom.filesystem.copy', {
      uuid: requestUuid,
      ...requestPayload,
    });

    const response = await this.rpc()
      .call<RpcSuccess>(request);

    return response.getResult();
  };

  move = async (payload: IFilesystemActionWithConflictPayload & {
    onFinish: (response: IFilesystemActionSuccessResponse) => void,
    onError: (error) => void,
  }): Promise<void> => {
    const {
      onFinish,
      onError,
      ...requestPayload
    } = payload;

    const requestUuid = uuid();

    const subscribeReq = new Request('dataroom.filesystem.move.listen', {
      uuid: requestUuid,
    });

    const subscription = await this.socketClient().subscribe<Subscription>(subscribeReq);

    subscription
      .on('filesystem.move.completed', (response) => {
        subscription.cancel();
        onFinish(response.params.payload);
      })
      .on('filesystem.move.error', (error) => {
        subscription.cancel();
        error.getData = () => ([error.params.payload]);
        onError(error);
      });

    const request = new Request('dataroom.filesystem.move', {
      uuid: requestUuid,
      ...requestPayload,
    });

    const response = await this.rpc()
      .call<RpcSuccess>(request);

    return response.getResult();
  };

  canBulkRename = async (payload: {
    dataroomId: number,
    folders: { id: number, name: string }[],
    files: { id: number, name: string }[],
    links: { id: number, name: string, href: string }[],
  }): Promise<IRenameResult> => {
    const request = new Request('dataroom.filesystem.can_rename', payload);
    const response = await this.rpc()
      .call<RpcSuccess>(request);

    return response.getResult().payload;
  };

  bulkRename = async (payload: {
    dataroomId: number,
    folders?: { id: number, name: string }[],
    files?: { id: number, name: string }[],
    links?: { id: number, name: string, href: string }[],
  } & {
    onFinish: (response: IFilesystemActionSuccessResponse) => void,
    onError: (error) => void,
  }): Promise<IRenameResult> => {
    const {
      onFinish,
      onError,
      ...requestPayload
    } = payload;

    const requestUuid = uuid();

    const subscribeReq = new Request('dataroom.filesystem.rename.listen', {
      uuid: requestUuid,
    });

    const subscription = await this.socketClient().subscribe<Subscription>(subscribeReq);

    subscription
      .on('filesystem.bulk_rename.completed', (response) => {
        subscription.cancel();
        onFinish(response.params.payload);
      })
      .on('filesystem.bulk_rename.error', (error) => {
        subscription.cancel();
        error.getData = () => ([error.params.payload]);
        onError(error);
      });

    const request = new Request('dataroom.filesystem.rename', {
      bulkRenameSessionId: requestUuid,
      ...requestPayload,
    });

    const response = await this.rpc()
      .call<RpcSuccess>(request);

    return response.getResult().payload;
  };

  remove = async (payload: {
    dataroomId: number,
    folders: { id: number }[],
    files: { id: number }[],
  }): Promise<void> => {
    const request = new Request('dataroom.filesystem.delete', payload);
    const response = await this.rpc()
      .call<RpcSuccess>(request);

    return response.getResult();
  };

  getIndexLimits = async (payload: {
    dataroomId: number,
    folderId: number,
  }): Promise<{
    maxFilesIndexValue: number,
    maxFoldersIndexValue: number,
  }> => {
    const request = new Request('dataroom.filesystem.get_max_index_values', payload);
    const response = await this.rpc()
      .call<RpcSuccess>(request);

    return response.getResult().payload;
  };

  getAllowedExtensions = async (
    payload: {
      dataroomId: number,
    },
  ): Promise<{ collection: { extension: string }[] }> => {
    let request = new Request('dataroom.filesystem.get_allowed_extensions', payload);
    let response = await this.rpc()
      .call<RpcSuccess>(request);

    return response.getResult().payload;
  };

  editIndexes = async (
    payload: {
      dataroomId: number,
      folders: {
        id: number,
        index: number,
      }[],
      files: {
        id: number,
        index: number,
      }[],
    },
  ): Promise<void> => {
    let request = new Request('dataroom.filesystem.edit_index', payload);
    let response = await this.rpc()
      .call<RpcSuccess>(request);

    return response.getResult();
  };

  pin = async (payload: {
    dataroomId: number,
    folders: { id: number }[],
    files: { id: number }[],
    isRecursive: boolean,
    onFinish: (response: IFilesystemActionSuccessResponse) => void,
    onError: (error: RpcError) => void,
  }): Promise<void> => {
    const {
      onFinish,
      onError,
      isRecursive,
      ...restPayload
    } = payload;

    const requestPayload = {
      ...restPayload,
      recursive: isRecursive,
    };

    const requestUuid = uuid();

    const subscribeReq = new Request('dataroom.filesystem.pin.listen', {
      uuid: requestUuid,
    });
    const subscription = await this.socketClient().subscribe<Subscription>(subscribeReq);

    subscription
      .on('filesystem.bulk_pin.completed', (response) => {
        subscription.cancel();
        onFinish(response.params.payload);
      })
      .on('filesystem.bulk_pin.error', (error) => {
        subscription.cancel();
        error.getData = () => [error.params.payload];
        onError(error);
      });

    const request = new Request('dataroom.filesystem.pin', {
      uuid: requestUuid,
      ...requestPayload,
    });

    const response = await this.rpc()
      .call<RpcSuccess>(request);
    return response.getResult();
  };

  unpin = async (payload: {
    dataroomId: number,
    folders: { id: number }[],
    files: { id: number }[],
    isRecursive: boolean,
    onFinish: (response: IFilesystemActionSuccessResponse) => void,
    onError: (error: RpcError) => void,
  }): Promise<void> => {
    const {
      onFinish,
      onError,
      isRecursive,
      ...restPayload
    } = payload;

    const requestPayload = {
      ...restPayload,
      recursive: isRecursive,
    };

    const requestUuid = uuid();

    const subscribeReq = new Request('dataroom.filesystem.unpin.listen', {
      uuid: requestUuid,
    });
    const subscription = await this.socketClient().subscribe<Subscription>(subscribeReq);

    subscription
      .on('filesystem.bulk_unpin.completed', (response) => {
        subscription.cancel();
        onFinish(response.params.payload);
      })
      .on('filesystem.bulk_unpin.error', (error) => {
        subscription.cancel();
        error.getData = () => [error.params.payload];
        onError(error);
      });

    const request = new Request('dataroom.filesystem.unpin', {
      uuid: requestUuid,
      ...requestPayload,
    });

    const response = await this.rpc()
      .call<RpcSuccess>(request);
    return response.getResult();
  };
}

export default FilesystemRepository;
