import React from 'react';
import { isFolder, isUrl } from '@/dataroom/domain/filesystem';
import { IFilesystemListItem } from '@/dataroom/domain/vo/collection/FilesystemListItem';
import { addHttpToUrl } from '@/Framework/url/helpers';
import { useDocumentPreviewContext } from '@/dataroom/application/DocumentPreviewContext';
import { SidebarMode } from '@/Framework/UI/Organisms/DocumentViewer/SidebarMode';

interface IProps {
  item: IFilesystemListItem,
  children: (args: { onClick: any }) => React.ReactElement,
  search?: string,
  mode?: SidebarMode,
  isRedactionModeEnabled?: boolean,
  onCloseCallback?: () => void,
}

const PreviewButtonHandler = ({
  item,
  search,
  mode,
  children,
  isRedactionModeEnabled = true,
  onCloseCallback = null,
}: IProps) => {
  const {
    previewFile,
    trackLinkPreviewAnalytics,
    setIsRedactionModeEnabled,
  } = useDocumentPreviewContext();

  const handlePreviewClick = async () => {
    if (isFolder(item)) {
      return;
    }

    if (isUrl(item)) {
      trackLinkPreviewAnalytics(item);
      window.open(addHttpToUrl(item.href), '_blank');
      return;
    }

    setIsRedactionModeEnabled(isRedactionModeEnabled);
    await previewFile(item, search, mode, onCloseCallback);
  };

  return children({
    onClick: handlePreviewClick,
  });
};

export default PreviewButtonHandler;
