import { useState } from 'react';
import { useDIContext } from '@/Framework/DI/DIContext';
import { getMessage } from '@/Framework/Message/Mapper/getMessage';
import { AlertManager } from '@dealroadshow/uikit/core/components/Alert';
import { messageCodes } from '@/Framework/Message/messages';
import LinkRepository from '@/dataroom/infrastructure/repository/LinkRepository';
import { useDataroomContext } from '@/dataroom/application/DataroomContext';
import { useCurrentFolderContext } from '@/dataroom/application/CurrentFolderContext';
import { useDataroomExplorerContext } from '@/dataroom/ui/common/DataroomExplorer/DataroomExplorerContext';
import DataroomErrorHandler from '@/dataroom/application/ErrorHandler';
import { getLinkValidationErrorMessage } from '@/Framework/UI/Organisms/FinalForm/validators/validateLink';

const useCreateLink = () => {
  const { container } = useDIContext();
  const { dataroom } = useDataroomContext();
  const { currentFolder } = useCurrentFolderContext();
  const { updateCollection: updateDataroomExplorerCollection } = useDataroomExplorerContext();
  const [isFetching, setIsFetching] = useState(false);

  const createLink = async (formData: {
    name: string,
    href: string,
  }) => {
    setIsFetching(true);

    try {
      const payload = {
        dataroomId: dataroom.id,
        parentFolderId: currentFolder.id,
        name: formData.name,
        href: formData.href,
      };

      await container.get<LinkRepository>(LinkRepository).create(payload);

      AlertManager.success(getMessage(messageCodes.DATAROOM_CREATE_LINK_SUCCESS, {
        name: payload.name,
      }));

      currentFolder && updateDataroomExplorerCollection();
    } catch (error) {
      if (('getData' in error) && error.getData()?.violations?.href?.includes?.('Hyperlink is incorrect')) {
        return { href: getLinkValidationErrorMessage('URL') };
      }
      container.get(DataroomErrorHandler).handleError(error);
    } finally {
      setIsFetching(false);
    }
    return null;
  };

  return {
    isFetching,
    createLink,
  };
};

export default useCreateLink;
