import { MouseSensor } from '@dnd-kit/core';

const shouldHandleEvent = (element: HTMLElement | null) => {
  let currentElement = element;

  while (currentElement) {
    if (currentElement.dataset && currentElement.dataset.noDnd) {
      return false;
    }
    currentElement = currentElement.parentElement;
  }

  return true;
};

class DragAndDropSensor extends MouseSensor {
  static activators = [
    {
      eventName: 'onMouseDown' as const,
      handler: ({ nativeEvent: event }) => {
        return shouldHandleEvent(event.target);
      },
    },
  ];
}

export default DragAndDropSensor;
