import env from '@framework/env/env';

const recaptchaConfig = {
  visible: {
    siteKey: env('RECAPTCHA_VISIBLE__SITE_KEY'),
  },
  invisible: {
    siteKey: env('RECAPTCHA_INVISIBLE__SITE_KEY'),
  },
};

export default recaptchaConfig;
