import React, { createContext, useContext } from 'react';
import { useDIContext } from '@/Framework/DI/DIContext';
import DisclaimerRepository from '@/dataroom/infrastructure/repository/DisclaimerRepository';
import { useDataroomContext } from './DataroomContext';
import { useDataroomTenantContext } from './DataroomTenantContext';
import SessionStorageRepository from '@/Framework/browser/storage/SessionStorageRepository';
import dmPortalUrl from '@/dmPortal/infrastructure/url/dmPortalUrl';

export const disclaimerSessionKey = (dataRoomId: number) => `disclaimerAccepted.${ dataRoomId }`;
const useDisclaimer = () => {
  const { container } = useDIContext();
  const { tenant } = useDataroomTenantContext();
  const { dataroom } = useDataroomContext();
  const { id: dataroomId } = dataroom;

  function acceptDisclaimer() {
    const sessionStorageRepository = container.get<SessionStorageRepository>(SessionStorageRepository);

    const disclaimerRepository = container.get<DisclaimerRepository>(DisclaimerRepository);

    sessionStorageRepository.setItem(disclaimerSessionKey(dataroomId), true);

    return disclaimerRepository.acceptDisclaimer({ dataroomId });
  }

  /**
   * Check if disclaimer was accepted
   */
  function isDisclaimerAccepted() {
    const sessionStorageRepository = container.get<SessionStorageRepository>(SessionStorageRepository);
    return !!sessionStorageRepository.getItem(disclaimerSessionKey(dataroomId));
  }

  /**
   * Create a log entry that the user didn't accepted Disclaimer
   */
  function rejectDisclaimer() {
    const disclaimerRepository = container.get<DisclaimerRepository>(DisclaimerRepository);

    disclaimerRepository.rejectDisclaimer({ dataroomId });
    window.location.href = dmPortalUrl.getUrl(`/${ tenant }`);
  }

  return {
    acceptDisclaimer,
    rejectDisclaimer,
    isDisclaimerAccepted,
  };
};
type TDisclaimerContext = ReturnType<typeof useDisclaimer>;

export const DisclaimerContext = createContext<TDisclaimerContext>(null);

/**
 * @return DisclaimerType
 */
export function useDisclaimerContext() {
  const context = useContext(DisclaimerContext);
  if (!context) {
    throw new Error('useDisclaimerContext must be used within a DisclaimerContext');
  }
  return context;
}

interface IProps {
  children: React.ReactNode,
}

function DisclaimerContextProvider({ children } : IProps) {
  return (
    <DisclaimerContext.Provider value={ useDisclaimer() }>
      { children }
    </DisclaimerContext.Provider>
  );
}

export default DisclaimerContextProvider;
