import { Class, Method, Transient, Value } from 'constitute';
import JsonRpcDispatcher, { interceptors } from '@dealroadshow/json-rpc-dispatcher';
import usersUrl from '@/users/infrastructure/usersUrl';
import AdapterFactory from '@/Framework/DI/Providers/Rpc/AdapterFactory';
import Container from '@/Framework/DI/Container';
import addSessionData from '@/users/application/Session/interceptors/rpc/addSessionData';
import checkSession from '@/users/application/Session/interceptors/rpc/checkSession';
import parseWrappedError from '@/Framework/api/Rpc/parseWrappedError';
import userPermissionsErrorHandler from '@/condor/application/DI/Rpc/interceptors/userPermissionsErrorHandler';
import OTelInstrument from '@/Framework/OTel/OTelInstrument';

const FetchAdapterFactory = new Class(AdapterFactory, Transient.with([
  new Value(usersUrl.getRpcUrl()),
  Container,
]));

export default new Method(
  ((adapterFactory: AdapterFactory, container: Container) => {
    const dispatcher = new JsonRpcDispatcher(adapterFactory.getAdapter({ otelPropagation: true }));
    type Args = Parameters<typeof dispatcher.call>;
    OTelInstrument(
      dispatcher,
      'call',
      container,
      () => 'rpc.call',
      (...args: Args) => {
        return {
          attributes: {
            'rpc.method': args[0].method,
          },
        };
      },
    );
    return dispatcher
      .interceptRequest(addSessionData(container))
      .interceptResponse(checkSession(container))
      .interceptResponse(userPermissionsErrorHandler(container))
      .interceptResponse(parseWrappedError())
      .interceptResponse(interceptors.response.transformErrorToException);
  }), [FetchAdapterFactory, Container],
);
