import React from 'react';
import { Reducer } from 'redux';
import { AppProps } from 'next/app';
import { createWrapper } from 'next-redux-wrapper';
import thunkMiddleware from 'redux-thunk';
import isServer from '@/Framework/Router/Next/isServer';
import { createContainer, PageRenderingState } from '@/Framework/DI';
import { createStore } from '@/Framework/Bootstrap/createStore';
import HealthCheckPage from '@/Framework/UI/Pages/HealthCheck';

const initialStoreValue = {};

const getWrappedComponent = (reducer, App) => {
  const container = createContainer(
    isServer() ? PageRenderingState.ServerSideRendering : PageRenderingState.ClientSideRendering,
  );
  const makeStore = createStore({
    container,
    reducer,
    middleware: [
      thunkMiddleware,
    ],
  });

  const store = makeStore(initialStoreValue);

  return createWrapper(makeStore).withRedux((props) => (
    <App
      container={ container }
      store={ store }
      { ...props }
    />
  ));
};

export const wrapper = (reducer: Reducer) => ({
  withRedux: (App) => {
    // On Server Side we need to recreate container for each request
    // On Client Side we need to remember container in the closure.

    if (isServer()) {
      return (props) => {
        const Component = getWrappedComponent(reducer, App);
        return <Component { ...props } />;
      };
    }

    return getWrappedComponent(reducer, App);
  },
});

export const healthCheck = (App: React.ElementType) => (props: AppProps) => {
  if (props.pageProps.healthcheck) {
    return <HealthCheckPage Component={ props.Component } pageProps={ props.pageProps } />;
  }
  return <App { ...props } />;
};
