import { Dependencies } from 'constitute';
import { v4 as uuid } from 'uuid';
import { RpcSuccess } from '@dealroadshow/json-rpc-dispatcher';
import Request from '@/Framework/api/Rpc/Request';
import { Subscription } from '@dealroadshow/socket-frontend-sdk';
import JsonRpcDispatcherFactory from '@/dataroom/application/DI/Rpc/HttpDispatcher';
import { IRedactionAction } from '@/dataroom/domain/vo/redaction/RedactionAction';
import SocketClientFactory from '@/dataroom/application/DI/Socket/Client';
import { IRedactedFilesListItem } from '@/dataroom/domain/vo/redaction/RedactedFilesType';
import { IFilesystemListItem } from '@/dataroom/domain/vo/collection/FilesystemListItem';
import { IRedactionAreaListItem } from '@/dataroom/domain/vo/redaction/RedactionAreaType';
import { IEntry, IRedaction } from '@/Framework/UI/Organisms/DocumentViewer/plugins/RedactionPlugin/types';
import { IRedactionTabCountType } from '@/dataroom/domain/vo/redaction/RedactionTabCountType';

@Dependencies(JsonRpcDispatcherFactory, SocketClientFactory)
class RedactionRepository {
  constructor(protected rpc: typeof JsonRpcDispatcherFactory, private socketClient: typeof SocketClientFactory) {
  }

  private applyAllSubscription = async (
    fileId: number,
    onFinish: (value?: any) => void,
    onError: (value?: any) => void,
  ): Promise<Subscription> => {
    const notificationId = `redaction-file-${ fileId }`;

    const subscribeReq = new Request('redaction.apply.listen', { notificationId });

    const subscription = await this.socketClient()
      .subscribe<Subscription>(subscribeReq);

    subscription
      .on('redaction.apply.completed', () => {
        subscription.cancel();
        onFinish();
      })
      .on('redaction.apply.error', () => {
        subscription.cancel();
        onError();
      });
    return subscription;
  };

  getRedactions = async (
    payload: {
      dataroomId: number,
      fileId: number,
    },
  ): Promise<{ collection: IRedaction[] }> => {
    const request = new Request('redaction.list.pending', payload);
    const response = await this.rpc()
      .call<RpcSuccess>(request);

    return response.getResult().payload;
  };

  getRedactionArea = async (
    payload: {
      sortBy: string,
      sortOrder: string,
      page: number,
      perPage: number,
      search: string,
      filesystemArea: string,
      dataroomId: number,
    },
  ): Promise<{ collection: IRedactionAreaListItem[] }> => {
    const request = new Request('redaction.redaction_area.list', payload);
    const response = await this.rpc()
      .call<RpcSuccess>(request);

    return response.getResult().payload;
  };

  getRedactedFiles = async (
    payload: {
      sortBy: string,
      sortOrder: string,
      page: number,
      perPage: number,
      search: string,
      filesystemArea: string,
      dataroomId: number,
    },
  ): Promise<{ collection: IRedactedFilesListItem[] }> => {
    const request = new Request('redaction.redacted_files.list', payload);
    const response = await this.rpc()
      .call<RpcSuccess>(request);

    return response.getResult().payload;
  };

  applyAllRedactions = async (
    payload: {
      dataroomId: number,
      fileId: number,
      onFinish: () => void,
      onError: () => void,
    },
  ): Promise<void> => {
    const {
      onFinish,
      onError,
      ...requestPayload
    } = payload;

    const request = new Request('redaction.apply_all', {
      ...requestPayload,
    });

    const subscription = await this.applyAllSubscription(requestPayload.fileId, onFinish, onError);

    try {
      const response = await this.rpc()
        .call<RpcSuccess>(request);

      return response.getResult();
    } catch (error) {
      subscription.cancel();
      throw error;
    }
  };

  search = async (payload: {
    dataroomId: number,
    fileId: number,
    search: string,
  }): Promise<{ collection: IEntry[] }> => {
    const request = new Request('redaction.search', payload);

    const response = await this.rpc()
      .call<RpcSuccess>(request);

    return response.getResult().payload;
  };

  saveAction = async (
    payload: {
      dataroomId: number,
      fileId: number,
      actions: IRedactionAction[],
    },
  ): Promise<void> => {
    const request = new Request('redaction.action.save', payload);

    const response = await this.rpc()
      .call<RpcSuccess>(request);

    return response.getResult();
  };

  removeAllPendingRedactions = async (
    payload: {
      dataroomId: number,
      fileId: number,
    },
  ): Promise<void> => {
    const request = new Request('redaction.remove_pending', payload);
    const response = await this.rpc()
      .call<RpcSuccess>(request);

    return response.getResult();
  };

  removePendingOnSelectedFiles = async (
    payload: {
      dataroomId: number,
      fileIds: number[],
      onFinish: () => void,
      onError: () => void,
    },
  ): Promise<void> => {
    const {
      onFinish,
      onError,
      ...requestPayload
    } = payload;

    const requestUuid = uuid();

    const subscribeReq = new Request('redaction.bulk.remove_pending.listen', { uuid: requestUuid });

    const subscription = await this.socketClient()
      .subscribe<Subscription>(subscribeReq);

    subscription
      .on('redaction.bulk.remove_pending.completed', () => {
        subscription.cancel();
        onFinish();
      })
      .on('redaction.bulk.remove_pending.fail', () => {
        subscription.cancel();
        onError();
      });

    const request = new Request('redaction.bulk.remove_pending', {
      ...requestPayload,
      uuid: requestUuid,
    });

    try {
      const response = await this.rpc()
        .call<RpcSuccess>(request);

      return response.getResult();
    } catch (error) {
      await subscription.cancel();
      throw error;
    }
  };

  removeAllAppliedRedactions = async (
    payload: {
      dataroomId: number,
      fileId: number,
    },
  ): Promise<void> => {
    const request = new Request('redaction.delete_redacted', payload);
    const response = await this.rpc()
      .call<RpcSuccess>(request);

    return response.getResult();
  };

  unredactSelectedFiles = async (
    payload: {
      dataroomId: number,
      fileIds: number[],
      uuid: string,
      onFinish: ({
        successCount,
        failCount,
      }: { successCount: number, failCount: number }) => void,
      onError: () => void,
    },
  ): Promise<void> => {
    const {
      onFinish,
      onError,
      ...requestPayload
    } = payload;

    const subscribeReq = new Request('redaction.bulk.unredact.listen', {
      ...requestPayload,
    });

    const subscription = await this.socketClient()
      .subscribe<Subscription>(subscribeReq);

    subscription
      .on('redaction.bulk.unredact.listen.completed', ({ params: { payload } }) => {
        subscription.cancel();
        const {
          success,
          fail,
        } = payload;
        onFinish({
          successCount: success,
          failCount: fail,
        });
      });
  };

  unredactAllFiles = async (
    payload: {
      dataroomId: number,
      filesystemArea: string,
      search: string,
      uuid: string,
      onFinish: ({
        successCount,
        failCount,
      }: { successCount: number, failCount: number }) => void,
      onError: () => void,
    },
  ): Promise<void> => {
    const {
      onFinish,
      onError,
      ...requestPayload
    } = payload;

    const subscribeReq = new Request('redaction.bulk.unredact_all.listen', {
      ...requestPayload,
    });

    const subscription = await this.socketClient()
      .subscribe<Subscription>(subscribeReq);

    subscription
      .on('redaction.bulk.unredact_all.listen.completed', ({ params: { payload } }) => {
        subscription.cancel();
        const {
          success,
          fail,
        } = payload;
        onFinish({
          successCount: success,
          failCount: fail,
        });
      });
  };

  checkFilesForRedactByKeyword = async (
    payload: {
      dataroomId: number,
      items: Pick<IFilesystemListItem, 'id' | 'type'>[],
    }): Promise<{ validation: boolean }> => {
    const request = new Request('redaction.check_count_files', payload);

    const response = await this.rpc()
      .call<RpcSuccess>(request);

    return response.getResult().payload;
  };

  scanByKeyword = async (
    payload: {
      search: string,
      dataroomId: number,
      items: Pick<IFilesystemListItem, 'id' | 'type'>[],
    }): Promise<{ validation: boolean }> => {
    const request = new Request('redaction.bulk_keyword_search', payload);

    const response = await this.rpc()
      .call<RpcSuccess>(request);

    return response.getResult().payload;
  };

  redactAllFiles = async (
    payload: {
      dataroomId: number,
      filesystemArea: string,
      uuid: string,
      onError: () => void,
      onFinish: ({
        successCount,
        failCount,
      }: { successCount: number, failCount: number }) => void,
    },
  ): Promise<void> => {
    const {
      onFinish,
      onError,
      ...requestPayload
    } = payload;

    const subscribeReq = new Request('redaction.bulk.redact_all.listen', {
      ...requestPayload,
    });

    const subscription = await this.socketClient()
      .subscribe<Subscription>(subscribeReq);

    subscription
      .on('redaction.bulk.redact_all.listen.completed', async ({ params: { payload } }) => {
        const { fileIds }: { fileIds: number[] } = payload;
        const totalLength = fileIds.length;

        const result = await Promise.allSettled(fileIds.map(async (fileId) => {
          // eslint-disable-next-line no-async-promise-executor
          return new Promise(async (resolve, reject) => {
            await this.applyAllSubscription(fileId, resolve, reject);
          });
        }));
        const successCount = result.filter(({ status }) => status === 'fulfilled').length;
        onFinish({
          successCount,
          failCount: totalLength - successCount,
        });

        subscription.cancel();
      });
  };

  redactSelectedFiles = async (
    payload: {
      dataroomId: number,
      uuid: string,
      fileIds: number[],
      onFinish: ({
        successCount,
        failCount,
      }: { successCount: number, failCount: number }) => void,
      onError: () => void,
    },
  ): Promise<void> => {
    const {
      onFinish,
      onError,
      ...requestPayload
    } = payload;

    const subscribeReq = new Request('redaction.bulk.redact.listen', {
      ...requestPayload,
    });

    const subscription = await this.socketClient()
      .subscribe<Subscription>(subscribeReq);

    const { fileIds } = requestPayload;
    const totalLength = fileIds.length;

    const result = await Promise.allSettled(fileIds.map(async (fileId) => {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        await this.applyAllSubscription(fileId, resolve, reject);
      });
    }));

    subscription.cancel();
    const successCount = result.filter(({ status }) => status === 'fulfilled').length;

    onFinish({
      successCount,
      failCount: totalLength - successCount,
    });
  };

  getTabCounter = async (payload: {
    dataroomId: number,
    filesystemArea: string,
  }): Promise<IRedactionTabCountType> => {
    const request = new Request('redaction.tab_counters', payload);

    const response = await this.rpc()
      .call<RpcSuccess>(request);

    return response.getResult().payload;
  };
}

export default RedactionRepository;
