import { createContext, useContext } from 'react';
import { useTimeZone } from './useTimeZone';

export const TimeZoneContext = createContext<ReturnType<typeof useTimeZone>>(null);

export const useTimeZoneContext = () => {
  const context = useContext(TimeZoneContext);
  if (!context) {
    throw new Error('useTimeZoneContext must be used within a TimeZoneContext');
  }
  return context;
};

const TimeZoneContextProvider = ({ children }: React.PropsWithChildren) => {
  return <TimeZoneContext.Provider value={ useTimeZone() }>{ children }</TimeZoneContext.Provider>;
};

export default TimeZoneContextProvider;
