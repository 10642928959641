import { Dependencies } from 'constitute';
import { RpcSuccess } from '@dealroadshow/json-rpc-dispatcher';
import Request from '@/Framework/api/Rpc/Request';
import JsonRpcDispatcherFactory from '@/dataroom/application/DI/Rpc/HttpDispatcher';
import { IPcrEntitiesCount } from '@/dataroom/domain/vo/types/PcrEntitiesCount';

@Dependencies(JsonRpcDispatcherFactory)
class DataroomPcrRepository {
  protected constructor(protected rpc: typeof JsonRpcDispatcherFactory) {
  }

  assignPcrAccount = async (
    payload: { dataroomId: number, userId: number, pcrAccount: string },
  ): Promise<null> => {
    const request = new Request('dataroom.users.assign_pcr_account', payload);
    const response = await this.rpc().call<RpcSuccess>(request);

    return response.getResult();
  };

  selfAssignPcrAccount = async (
    payload: { dataroomId: number, pcrAccount: string },
  ): Promise<null> => {
    const request = new Request('dataroom.users.current.assign_pcr_account', payload);
    const response = await this.rpc().call<RpcSuccess>(request);

    return response.getResult();
  };

  getPcrAccountList = async (payload: { dataroomId: number }): Promise<string[]> => {
    const request = new Request('dataroom.pcr.get_account_list', payload);
    const response = await this.rpc().call<RpcSuccess>(request);

    return response.getResult().payload;
  };

  getPcrEntitiesCount = async (payload: { dataroomId: number }): Promise<IPcrEntitiesCount> => {
    const request = new Request('dataroom.pcr.get_entities_count', payload);
    const response = await this.rpc().call<RpcSuccess>(request);

    return response.getResult().payload;
  };
}

export default DataroomPcrRepository;
