import React, { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react';
import ErrorCodeHelper from '@finsight/error-codes';
import { SortOrder } from '@dealroadshow/uikit/core/components/Table/DataTable/interfaces/SortOrder';
import { getErrorMessage } from '@/Framework/Message/Mapper/getMessage';
import useCancelUploadModal
  from '@/dataroom/ui/common/DataroomExplorer/Modals/UploaderModal/CancelUploadModal/useCancelUploadModal';
import * as column from '@/dataroom/ui/common/DataroomExplorer/Modals/UploaderModal/constants';
import UploadMultipleFiles from '@/dataroom/ui/common/DataroomExplorer/Modals/UploaderModal/UploadMultipleFiles';
import { AlertManager } from '@dealroadshow/uikit/core/components/Alert';
import useUploaderModal from '@/dataroom/ui/common/DataroomExplorer/Modals/UploaderModal/useUploaderModal';
import FileSystemStructure from '@/dataroom/ui/common/DataroomExplorer/Modals/UploaderModal/models/FileSystemStructure';
import { useDIContext } from '@/Framework/DI/DIContext';
import FileUploadRepository from '@/dataroom/infrastructure/repository/FileUploadRepository';
import { useDataroomContext } from '@/dataroom/application/DataroomContext';
import Upload, { UploadId } from '@/dataroom/ui/common/DataroomExplorer/Modals/UploaderModal/models/Upload';
import {
  ProcessingEvent,
  UploadEvent,
  UploadingState,
} from '@dealroadshow/file-uploader';
import FolderRepository from '@/dataroom/infrastructure/repository/FolderRepository';
import { useCurrentFolderContext } from '@/dataroom/application/CurrentFolderContext';
import { useFolderTreeContext } from '@/dataroom/application/folderTree/FolderTreeContext';
import { useDataroomExplorerContext } from '@//dataroom/ui/common/DataroomExplorer/DataroomExplorerContext';
import useAllowedFileTypes from '@/dataroom/application/FileUploader/useAllowedFileTypes';
import useProgress from '@/dataroom/application/FileUploader/useProgress';
import useUploaderHealthCheck from '@/dataroom/application/FileUploader/useUploaderHealthCheck';
import ResponseError from '@/dataroom/ui/common/DataroomExplorer/Modals/UploaderModal/models/ResponseError';
import * as sort from '@/dataroom/ui/common/DataroomExplorer/Modals/UploaderModal/sort';
import RetryOne from './uploadStrategy/RetryOne';
import UploadError from '@/dataroom/ui/common/DataroomExplorer/Modals/UploaderModal/models/UploadError';
import RetryAll from './uploadStrategy/RetryAll';
import UploadAll from './uploadStrategy/UploadAll';
import { chunkSize, SAME_TIME_FILES_UPLOAD_LIMIT } from '@/dataroom/application/FileUploader/constants';
import emitSyntheticActivity from '@/Framework/browser/timers/emitSyntheticActivity';
import config from '@/Framework/config';
import FileWithUploadAndPath
  from '@/dataroom/ui/common/DataroomExplorer/Modals/UploaderModal/models/FileWithUploadAndPath';
import { IUploadStrategy } from '@/dataroom/application/FileUploader/uploadStrategy/IUploadStrategy';
import validateFile from '@/dataroom/application/FileUploader/validateFile';
import { isValidExtension } from '@/dataroom/ui/common/DataroomExplorer/Modals/UploaderModal/helpers';
import Logger from '@/Framework/browser/log/Logger';
import { useDataroomTenantContext } from '@/dataroom/application/DataroomTenantContext';
import { DataroomStageName } from '@/dataroom/domain/vo/DataroomStageName';

function handleBeforeUnload(event) {
  event.preventDefault();
  event.returnValue = '';
}

const defaultSort = {
  sortBy: column.PATH,
  sortOrder: 'asc' as SortOrder,
};

const isDefaultSort = (sort) => sort.sortBy === defaultSort.sortBy && sort.sortOrder === defaultSort.sortOrder;

function useMultipleFilesUploader() {
  const { tenant } = useDataroomTenantContext();
  const { container } = useDIContext();
  const {
    dataroom,
    getDataroomByName,
  } = useDataroomContext();
  const dataroomId = dataroom?.id;
  const { currentFolder } = useCurrentFolderContext();
  const { updateFolderTree } = useFolderTreeContext();
  const {
    updateCollection: updateDataroomExplorerCollection,
    reset: resetDataroomExplorer,
  } = useDataroomExplorerContext();
  const {
    allowedFileTypes,
    isAllowedFileTypesFetching,
    getAllowedFileTypes,
  } = useAllowedFileTypes();
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);

  const {
    triggerUploadAttempt,
    triggerSelectFilesAttempt,
    resetSelectFilesAttempts,
    startUploadingHealthCheck,
    stopUploadingHealthCheck,
    triggerUploadingHealthCheck,
  } = useUploaderHealthCheck();

  const {
    increaseTotalDifficulty: increaseProcessingTotalDifficulty,
    makeProgress: makeProcessingProgress,
    reset: resetProcessingForUpload,
    percentage: processingPercentage,
  } = useProgress(triggerUploadingHealthCheck);

  const {
    increaseTotalDifficulty: increaseUploadingTotalDifficulty,
    makeProgress: makeUploadingProgress,
    reset: resetUploadingProgress,
    percentage: uploadingPercentage,
  } = useProgress(triggerUploadingHealthCheck);

  const uploadingFilesMapRef = useRef(new Map<UploadId, FileWithUploadAndPath>());
  const isAutoScrollEnabled = useRef<boolean>(false);
  const abortControllerRef = useRef(null);
  const isCanceled = useRef(false);
  const sortParams = useRef(defaultSort);

  const setStateToAutoScroll = (state: boolean) => {
    isAutoScrollEnabled.current = state;
  };
  const disableAutoScroll = useCallback(() => {
    setStateToAutoScroll(false);
  }, []);

  const enableAutoScroll = useCallback(() => {
    isDefaultSort(sortParams.current) && setStateToAutoScroll(true);
  }, []);

  const setSortParams = (newSortParams: {
    sortBy: string,
    sortOrder: SortOrder,
  }) => {
    sortParams.current = { ...newSortParams };
  };
  const setUploadingFiles = (uploadingFiles: Map<string, FileWithUploadAndPath>) => {
    uploadingFilesMapRef.current = uploadingFiles;
  };

  useEffect(() => {
    dataroomId && getAllowedFileTypes(dataroomId);
  }, [dataroomId]);

  const [allFiles, setAllFiles] = useState<FileWithUploadAndPath[]>([]);
  const [isAddingChildren, setIsAddingChildren] = useState(false);
  const [isAddedChildren, setIsAddedChildren] = useState(false);
  const [isUploadOnlyFoldersDone, setIsUploadOnlyFoldersDone] = useState(false);

  const initializedActiveUploads = useRef<Awaited<ReturnType<
    typeof initFileUpload | typeof fileUploadRepository.uploadSmallFile
  >>[]>([]);
  const permissions = useRef({});
  const setPermissionGroups = (values) => {
    permissions.current = { ...values };
  };

  const fileSystemStructureRef = useRef(new FileSystemStructure());
  const setFileSystemStructure = (fileSystemStructure: FileSystemStructure) => {
    fileSystemStructureRef.current = fileSystemStructure;
  };
  const addFileSystemStructure = setFileSystemStructure;

  const foldersNumber = fileSystemStructureRef.current.countFolders();
  const isStartUploadProcess = (allFiles.length || !!foldersNumber) && !isAddedChildren;

  useEffect(() => {
    if (isStartUploadProcess) {
      window.addEventListener('beforeunload', handleBeforeUnload);
    } else {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    }
  }, [isStartUploadProcess]);

  const deleteFile = (fileToDelete) => {
    const filteredFiles = allFiles.filter((file) => fileToDelete !== file);
    setAllFiles(filteredFiles);
    fileSystemStructureRef.current.removeFile(fileToDelete);
    uploadingFilesMapRef.current.delete(fileToDelete.upload.id);
    if (filteredFiles.length === 0 && fileSystemStructureRef.current.countFolders() === 0) {
      clearUploaderData();
    }
  };

  const addChildrenDifficulty = useRef(0);

  const setAddChildrenDifficulty = (difficulty: number) => {
    addChildrenDifficulty.current = difficulty;
  };

  const clearUploaderData = () => {
    // we have no access to upload which are in "initializing" state (init request is not finished
    // that's why we need to cancel them via abortController
    abortControllerRef.current?.abort();
    const cancelAlreadyInitializedUploadProcessing = () => {
      while (initializedActiveUploads.current.length !== 0) {
        const activeUpload = initializedActiveUploads.current.pop();
        activeUpload.cancel();
      }
    };
    permissions.current = {};
    setIsUploading(false);
    isCanceled.current = true;
    setAllFiles([]);
    setFileSystemStructure(new FileSystemStructure());
    setUploadingFiles(new Map<string, FileWithUploadAndPath>());
    setIsUploadOnlyFoldersDone(false);
    setIsAddedChildren(false);
    cancelAlreadyInitializedUploadProcessing();
    resetProcessingForUpload();
    resetUploadingProgress();
    stopUploadingHealthCheck();
  };

  const sortFiles = useCallback((
    sortBy: string = sortParams.current.sortBy,
    sortOrder: SortOrder = sortParams.current.sortOrder,
    files: FileWithUploadAndPath[] = allFiles,
  ) => {
    setStateToAutoScroll(isDefaultSort(sortParams.current));
    setSortParams({
      sortBy,
      sortOrder,
    });
    if (sortBy === column.STATUS) {
      setAllFiles([...files.sort(sort.getCompareFunction([
        sort.compareByStatus,
        sort.compareByPath,
        sort.compareByName,
      ], sortOrder))]);
    } else if (sortBy === column.NAME) {
      setAllFiles([...files.sort(sort.getCompareFunction([
        sort.compareByName,
        sort.compareByPath,
      ], sortOrder))]);
    } else if (sortBy === column.PATH) {
      setAllFiles([...files.sort(sort.getCompareFunction([
        sort.compareByPath,
        sort.compareByName,
      ], sortOrder))]);
    }
  }, [allFiles]);

  const addFilesToUpload = (files: FileWithUploadAndPath[]) => {
    resetSelectFilesAttempts();
    sortFiles(sortParams.current.sortBy, sortParams.current.sortOrder, allFiles.concat(files));
  };

  const addChildren = async () => {
    startUploadingHealthCheck();
    setIsUploading(false);
    try {
      setIsAddingChildren(true);

      const folderRepository = container.get<FolderRepository>(FolderRepository);
      const customPermissionGroups = Object.keys(permissions.current)
        .filter((group) => !['selectedItems', 'allItems'].includes(group))
        .reduce((acc, permission) => {
          permissions.current[permission].forEach((id) => acc.push({
            id,
            permission,
          }));
          return acc;
        }, []);
      let previousPercentage = 0;
      const result = await folderRepository.addChildren({
        dataroomId: dataroom.id,
        folderId: currentFolder.id,
        customPermissionGroups,
        children: fileSystemStructureRef.current.serialize(),
      }, () => (percentage) => {
        const currentStepPercentage = percentage - previousPercentage;
        makeUploadingProgress(addChildrenDifficulty.current * (currentStepPercentage / 100), true);
        previousPercentage = percentage;
      });
      setIsAddedChildren(true);
      if (result) {
        updateFolderTree(currentFolder.id);
        resetDataroomExplorer();
        updateDataroomExplorerCollection();

        tenant === config.tenant.tenantResearchRoom.code
        && dataroom.stage?.name === DataroomStageName.NotLive
        && getDataroomByName(dataroom.name);
      }
    } catch (e) {
      if (e.error?.message) {
        Logger.error('File upload addChildren error', {
          message: e.error.message,
          data: JSON.stringify(e.error?.data || {}),
        });
        AlertManager.error(e.error.message);
      }
      AlertManager.error(getErrorMessage(e));
    } finally {
      setIsAddingChildren(false);
      stopUploadingHealthCheck();
    }
  };

  const fileUploadRepository = container.get<FileUploadRepository>(FileUploadRepository);

  const uploadOnlyFolders = async () => {
    abortControllerRef.current = new AbortController();
    isCanceled.current = false;
    const foldersCount = fileSystemStructureRef.current.countFolders();
    increaseUploadingTotalDifficulty(foldersCount);
    await addChildren();
    setIsUploadOnlyFoldersDone(true);
  };

  const uploadFiles = (filesToUpload: FileWithUploadAndPath[], uploadStrategy: IUploadStrategy) => {
    abortControllerRef.current = new AbortController();
    isCanceled.current = false;
    const uploadQueue = (
      queue: FileWithUploadAndPath[],
      sameTileUploadLimit: number,
      uploadStrategy: IUploadStrategy,
    ) => {
      for (let i = 0; i < sameTileUploadLimit; i++) {
        const file = queue.shift();
        if (file) {
          const uploadNext = () => {
            if (!isCanceled.current) {
              uploadQueue(queue, 1, uploadStrategy);
            }
          };
          uploadFile(file, uploadStrategy)
            .then(uploadNext, uploadNext);
        }
      }
    };

    startUploadingHealthCheck();
    checkProhibitedExtensions(filesToUpload);
    isCanceled.current = false;
    enableAutoScroll();
    setIsUploading(true);
    setUploadingFiles(new Map<string, FileWithUploadAndPath>());
    uploadStrategy.prepareForUpload(filesToUpload, increaseUploadingTotalDifficulty, setAddChildrenDifficulty);
    uploadQueue([...filesToUpload], SAME_TIME_FILES_UPLOAD_LIMIT, uploadStrategy);
  };

  const checkProhibitedExtensions = (filesToUpload) => {
    const filterAllowedFiles = (name) => !isValidExtension(allowedFileTypes, name);
    const prohibitedExtensionsFiles = filesToUpload.map(({ name }) => name)
      .filter(filterAllowedFiles);

    if (prohibitedExtensionsFiles.length) {
      fileUploadRepository.notifyProhibitedExtensions({
        dataroomId,
        parentFolderId: currentFolder.id,
        prohibitedExtensionsFiles,
      });
    }
  };

  const initFileUpload = async (
    file: FileWithUploadAndPath,
    uploadStrategy,
    options: { abortController: AbortController },
  ) => {
    try {
      const uploadProcessing = await fileUploadRepository.initBigFileUpload(dataroom.id, file, chunkSize, options);
      file.upload.uploadProcessing = uploadProcessing;
      file.upload.status = Upload.PENDING;
      file.upload.id = uploadProcessing.getUuid();
      uploadingFilesMapRef.current.set(file.upload.id, file);
      return uploadProcessing;
    } catch (e) {
      // TODO is subscribe error caught here? We need compare this with "subscribe error"
      //  and if they are the same - remove subscribe error  try-catch
      // eslint-disable-next-line no-console
      console.error('init error', e);
      let error;
      if (e.data) {
        error = new ResponseError(e.data);
      } else if (e.error?.code === ErrorCodeHelper.getCodeByName('DATAROOM_UPLOADER_ARCHIVE_INVALID_FILES')) {
        file.upload.addError(new UploadError('File extension within archive not on whitelist'));
      } else {
        error = new UploadError('Connectivity error');
      }
      makeUploadingProgress(
        uploadStrategy.getFileProgressTotalChunks(file) +
        uploadStrategy.getFileProgressJoinDone() +
        uploadStrategy.getFileProgressJoinRequest(),
        true,
      );
      file.upload.addError(error);
      throw error;
    } finally {
      makeUploadingProgress(uploadStrategy.getFileProgressInit(), true);
    }
  };

  // eslint-disable-next-line no-async-promise-executor,max-len
  const uploadFile = async (file: FileWithUploadAndPath, uploadStrategy: IUploadStrategy) => new Promise(async (resolve, reject) => {
    const error = await validateFile(file, uploadStrategy, allowedFileTypes);
    if (error) {
      file.upload.addError(error);
      makeUploadingProgress(uploadStrategy.getFileProgressTotal(file), true);
      reject(error);
      return;
    }

    if (file.size > chunkSize) {
      let initializedUploadProcessing: Awaited<ReturnType<typeof initFileUpload>>;
      try {
        initializedUploadProcessing = await initFileUpload(
          file,
          uploadStrategy,
          { abortController: abortControllerRef.current },
        );
      } catch (e) {
        reject(new Error('Unable init upload.'));
      }

      if (!initializedUploadProcessing) {
        return;
      }
      initializedActiveUploads.current.push(initializedUploadProcessing);
      file.upload.uploadProcessing = initializedUploadProcessing;
      initializedUploadProcessing
        .on(UploadEvent.uploadChunkDone, (payload: UploadingState) => {
          const file = uploadingFilesMapRef.current.get(payload.uuid);
          if (!file || file.upload.isFailure()) {
            return false;
          }
          // TODO simplify
          const progress = ((100 / payload.totalChunks) * payload.successfulChunks).toFixed(2);
          file.upload.percentage = parseFloat(parseFloat(progress)
            .toFixed(2));
          file.upload.status = Upload.UPLOADING;
          if (!file.upload.errors.length) {
            makeUploadingProgress(uploadStrategy.getFileProgressChunk(), true);
          }
          return null;
        })
        .on(UploadEvent.uploadChunkError, (payload: UploadingState) => {
          const file = uploadingFilesMapRef.current.get(payload.uuid);
          if (!file) {
            return false;
          }
          uploadingFilesMapRef.current.delete(payload.uuid);
          if (!file.upload.errors.length) {
            file.upload.addError(new ResponseError(payload.error));
            makeUploadingProgress(
              uploadStrategy.getFileProgressRemaining(payload.totalChunks, payload.successfulChunks),
              true,
            );
          }

          reject();
          return null;
        })
        .on(UploadEvent.uploadCombineChunksRequested, (payload: UploadingState) => {
          const file = uploadingFilesMapRef.current.get(payload.uuid);
          if (!file) {
            return false;
          }
          file.upload.setCombineChunksTimeout(
            () => {
              file.upload.addError(new UploadError('Unknown error'));
              file.upload.uploadProcessing.cancel();
              makeUploadingProgress(uploadStrategy.getFileProgressJoinDone(), true);
            },
            emitSyntheticActivity,
            file.size / 5000, // There is an additional timout inside file.upload.setCombineChunksTimeout method
            config.session.idleTimeout / 2,
          );
          return makeUploadingProgress(uploadStrategy.getFileProgressJoinRequest(), true);
        })
        .on(UploadEvent.uploadCombineChunksRequestError, (payload: UploadingState) => {
          const file = uploadingFilesMapRef.current.get(payload.uuid);
          if (!file) {
            return false;
          }
          file.upload.clearCombineChunksTimeout();
          uploadingFilesMapRef.current.delete(payload.uuid);
          file.upload.addError(new ResponseError(payload.error));
          reject();
          return makeUploadingProgress(uploadStrategy.getFileProgressJoinDone(), true);
        })
        .on(UploadEvent.cancel, (payload: UploadingState) => {
          const file = uploadingFilesMapRef.current.get(payload.uuid);
          if (!file) {
            return false;
          }
          uploadingFilesMapRef.current.delete(payload.uuid);
          reject();
          return null;
        })
        .on(ProcessingEvent.processingDone, async (payload) => {
          const file = uploadingFilesMapRef.current.get(payload.uuid);
          if (!file) {
            return false;
          }
          file.upload.clearCombineChunksTimeout();
          uploadingFilesMapRef.current.delete(payload.uuid);

          if (!file.upload.errors.length) {
            resolve(file);
            file.upload.status = Upload.SUCCESS;
            file.upload.errors = [];
            makeUploadingProgress(uploadStrategy.getFileProgressJoinDone(), true);
            if (isUploadCompleteWithNoErrors()) {
              await addChildren();
            }
          }
          return null;
        })
        .on(ProcessingEvent.processingJoinChunksError, async (payload) => {
          const file = uploadingFilesMapRef.current.get(payload.uuid);
          if (!file) {
            return false;
          }
          file.upload.clearCombineChunksTimeout();
          uploadingFilesMapRef.current.delete(payload.uuid);
          file.upload.addError(new ResponseError(payload));
          reject();
          return makeUploadingProgress(uploadStrategy.getFileProgressJoinDone(), true);
        })
        .on(ProcessingEvent.disconnect, () => {
          setTimeout(() => {
            uploadingFilesMapRef.current.forEach((file) => {
              if (file.upload.isJoining()) {
                file.upload.addError(new UploadError('Connectivity error'));
                file.upload.uploadProcessing.cancel();
                makeUploadingProgress(uploadStrategy.getFileProgressJoinRequest(), true);
              }
            });
          }, 30 * 1000);
        });
    } else {
      try {
        file.upload.status = Upload.UPLOADING;

        const uploadProcessing = await fileUploadRepository.uploadSmallFile(
          dataroom.id,
          file,
          chunkSize,
          { abortController: abortControllerRef.current },
        );
        file.upload.uploadProcessing = uploadProcessing;
        file.upload.id = uploadProcessing.getUuid();

        if (!file.upload.id) {
          Logger.error('File upload id is empty', { data: { id: file.upload.id } });
        }

        file.upload.status = Upload.SUCCESS;
        file.upload.errors = [];
        initializedActiveUploads.current.push(uploadProcessing);
        resolve(file);
      } catch (e) {
        if (e.error?.code === ErrorCodeHelper.getCodeByName('DATAROOM_UPLOADER_ARCHIVE_INVALID_FILES')) {
          file.upload.addError(new UploadError('File extension within archive not on whitelist'));
        } else {
          file.upload.addError(new UploadError('Connectivity error'));
        }
      } finally {
        makeUploadingProgress(uploadStrategy.getFileProgressTotal(file), true);
      }

      if (isUploadCompleteWithNoErrors()) {
        await addChildren();
      }
    }
  });

  const uploadAll = () => {
    triggerUploadAttempt();

    if (!allFiles.length) {
      return uploadOnlyFolders();
    }

    return uploadFiles(allFiles, new UploadAll());
  };

  const retryAll = () => {
    resetUploadingProgress();
    const failedFiles = allFiles.filter((file) => file.upload.status === Upload.FAILURE);

    // TODO try move to strategy
    setAllFiles((files) => (
      files.map((file) => {
        if (file.upload.status === Upload.FAILURE) {
          file.upload.status = null;
          file.upload.errors = [];
        }
        return file;
      })
    ));
    return uploadFiles(failedFiles, new RetryAll());
  };

  const retryOne = (fileForRetry) => {
    setAllFiles((files) => (
      files.map((file) => {
        if (file === fileForRetry) {
          file.upload.errors = [];
          file.upload.status = Upload.PENDING;
        }
        return file;
      })
    ));
    return uploadFiles([fileForRetry], new RetryOne());
  };

  const getSuccessFiles = () => allFiles.filter((file) => file.upload.isSuccess());
  const isSomeFileSuccess = () => allFiles.some((file) => file.upload.isSuccess());
  const isUploadComplete = () => !!allFiles.length &&
    (allFiles.every((file) => file.upload.isFailure() || file.upload.isSuccess()));
  const isUploadErrors = () => allFiles.some((file) => file.upload.isFailure());
  const isRetryUploading = () => allFiles.some((file) => file.upload.isProcessing() && !!file.upload.errors.length);
  const isUploadCompleteWithNoErrors = () => !!allFiles.length && allFiles.every((file) => file.upload.isSuccess());

  const uploadPercentage = uploadingPercentage; // TMP fix

  return {
    isAllowedFileTypesFetching,
    addFilesToUpload,
    allFiles,
    getSuccessFiles,
    addFileSystemStructure,
    uploadAllFiles: uploadAll,
    triggerSelectFilesAttempt,
    uploadProcessedFiles: addChildren,
    deleteFile,
    clearUploaderData,
    isUploadComplete,
    isUploadErrors,
    isRetryUploading,
    isSomeFileSuccess,
    isUploadCompleteWithNoErrors,
    increaseProcessingTotalDifficulty,
    resetProcessingForUpload,
    makeProcessingProgress,
    stopUploadingHealthCheck,
    uploadPercentage,
    processingPercentage,
    fileSystemStructure: fileSystemStructureRef.current,
    isAddedChildren,
    isAddingChildren,
    isUploadOnlyFoldersDone,
    retryAll,
    isProcessing,
    setIsProcessing,
    isUploading,
    retryFile: retryOne,
    sortFiles,
    sortParams,
    setPermissionGroups,
    isAutoScrollEnabled,
    enableAutoScroll,
    disableAutoScroll,
    foldersNumber,
  };
}

interface IProps {
  children: React.ReactNode,
}

type FileUploaderContextType = ReturnType<typeof useMultipleFilesUploader>
  & ReturnType<typeof useUploaderModal>
  & ReturnType<typeof useCancelUploadModal>;

export const FileUploaderContext = createContext<FileUploaderContextType>(null);

export function useFileUploaderContext() {
  const context = useContext(FileUploaderContext);
  if (!context) {
    throw new Error('useFileUploaderContext must be used within a FileUploaderContext');
  }
  return context;
}

function FileUploaderContextProvider({ children }: IProps) {
  const multipleFileUploader = useMultipleFilesUploader();

  return (
    <FileUploaderContext.Provider value={
      {
        ...multipleFileUploader,
        ...useUploaderModal(multipleFileUploader.clearUploaderData),
        ...useCancelUploadModal(),
      }
    }
    >
      <UploadMultipleFiles>
        { children }
      </UploadMultipleFiles>
    </FileUploaderContext.Provider>
  );
}

export default FileUploaderContextProvider;
