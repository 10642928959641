interface IParams {
  pcrEntitiesCount: number,
  pcrLimit: number,
  pcrAccountList: string[],
  currentAccount?: string,
}

export const fieldName = 'pcrAccount';

export default (pcrParams: IParams) => (values) => {
  if (
    pcrParams.pcrEntitiesCount > pcrParams.pcrLimit
    || (
      pcrParams.pcrLimit - pcrParams.pcrEntitiesCount < 1
      && pcrParams.currentAccount
      && pcrParams.pcrAccountList.every((account) => account !== values[fieldName]?.value)
    )
  ) {
    return { [fieldName]: 'Entity limit exceeded.' };
  }
  return null;
};
