import cn from 'classnames';
import Div100vh from '@/Framework/UI/Templates/Div100vh';
import styles from './applicationWrp.scss';
import { APPLICATION_WRP_ID } from './constants';

interface IProps {
  children: React.ReactNode,
  className?: string,
}

const ApplicationWrp = ({ children, className }: IProps) => {
  return (
    <Div100vh
      id={ APPLICATION_WRP_ID }
      className={ cn(styles.wrp, className) }
    >
      { children }
    </Div100vh>
  );
};

export default ApplicationWrp;
