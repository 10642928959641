import { Dependencies } from 'constitute';
import { RpcNotification, RpcSuccess } from '@dealroadshow/json-rpc-dispatcher';
import { Subscription } from '@dealroadshow/socket-frontend-sdk';
import { v4 as uuid } from 'uuid';
import JsonRpcDispatcherFactory from '@/dataroom/application/DI/Rpc/HttpDispatcher';
import Request from '@/Framework/api/Rpc/Request';
import downloadFile from '@/Framework/api/downloadFile';
import ExportToExcel from '@/dataroom/application/models/ExportToExcel';
import SocketClientFactory from '@/dataroom/application/DI/Socket/Client';

@Dependencies(JsonRpcDispatcherFactory, SocketClientFactory)
export default class ExportRepository {
  constructor(private rpc: typeof JsonRpcDispatcherFactory, private socketClient: typeof SocketClientFactory) {
  }

  sendExportToExcelRequest = async (
    payload: {
      dataroomId: number,
      exportName: string,
      uuid: string,
    },
  ): Promise<{ payload: { hash: string } }> => {
    const { exportName, ...otherPayload } = payload;

    const request = new Request(`dataroom.export.${ exportName }`, otherPayload);
    const response = await this.rpc().call<RpcSuccess>(request);

    return response.getResult();
  };

  makeExportOversize = async (
    payload: {
      hash: string,
      dataroomId: number,
    },
  ): Promise<void> => {
    const request = new Request('dataroom.export.mark_oversized', payload);
    const response = await this.rpc().call<RpcSuccess>(request);

    return response.getResult();
  };

  requestExportToExcel = async (
    {
      dataroomId,
      exportName,
      onFinish,
      onError,
      ...otherPayload
    }: {
      dataroomId: number,
      exportName: string,
      onFinish: (uuid: string) => void,
      onError: (uuid: string) => void,
    },
  ): Promise<ExportToExcel> => {
    const requestUuid = uuid();

    const subscribeReq = new Request('dataroom.export.listen', { uuid: requestUuid });

    const subscription = await this.socketClient().subscribe<Subscription>(subscribeReq);

    subscription
        .on('export.completed', (notification: RpcNotification) => {
          downloadFile(notification.params.payload.url);
          onFinish(requestUuid);
        })
        .on('export.failed', () => {
          onError(requestUuid);
        });

    const response = await this.sendExportToExcelRequest({
      ...otherPayload,
      dataroomId,
      uuid: requestUuid,
      exportName,
    });

    const onTimeOut = (excelExport) => {
      subscription.cancel();
      onFinish(excelExport.uuid);
      this.makeExportOversize({
        dataroomId: excelExport.dataroomId,
        hash: excelExport.hash,
      });
    };

    return new ExportToExcel(dataroomId, requestUuid, response.payload.hash, onTimeOut);
  };
}
