import { useState } from 'react';
import DataroomErrorHandler from '@/dataroom/application/ErrorHandler';
import { useDIContext } from '@/Framework/DI/DIContext';
import RedactionRepository from '@/dataroom/infrastructure/repository/RedactionRepository';
import { useDataroomContext } from '@/dataroom/application/DataroomContext';
import { AlertManager } from '@dealroadshow/uikit/core/components/Alert';
import { getMessage } from '@/Framework/Message/Mapper/getMessage';
import { messageCodes } from '@/Framework/Message/messages';

export default function useRemove() {
  const { container } = useDIContext();
  const { dataroom } = useDataroomContext();

  const [isFetching, setIsFetching] = useState<boolean>(false);

  const removeAllPendingRedactions = async (fileId: number, fileName: string) => {
    setIsFetching(true);

    try {
      const payload = {
        dataroomId: dataroom.id,
        fileId,
      };

      const redactionRepository = container.get<RedactionRepository>(RedactionRepository);

      await redactionRepository.removeAllPendingRedactions(payload);
      AlertManager.success(getMessage(messageCodes.DATAROOM_REMOVE_REDACTIONS_ON_FILE_SUCCESS, {
        fileName,
        redactionType: 'pending',
      }));
    } catch (error) {
      container.get(DataroomErrorHandler)
        .handleError(error);
    } finally {
      setIsFetching(false);
    }
  };

  const removeAllAppliedRedactions = async (fileId: number, fileName: string) => {
    setIsFetching(true);

    try {
      const payload = {
        dataroomId: dataroom.id,
        fileId,
      };

      const redactionRepository = container.get<RedactionRepository>(RedactionRepository);

      await redactionRepository.removeAllAppliedRedactions(payload);
      AlertManager.success(getMessage(messageCodes.DATAROOM_REMOVE_REDACTIONS_ON_FILE_SUCCESS, {
        fileName,
        redactionType: 'applied',
      }));
    } catch (error) {
      container.get(DataroomErrorHandler)
        .handleError(error);
    } finally {
      setIsFetching(false);
    }
  };

  const removePendingOnSelectedFiles = async (fileIds: number[]) => (
    // eslint-disable-next-line no-async-promise-executor
    new Promise(async (resolve, reject) => {
      setIsFetching(true);

      const onFinish = () => {
        setIsFetching(false);
        resolve(true);
        AlertManager.success(getMessage(messageCodes.DATAROOM_UNREDACT_SELECTED_FILES_SUCCESS, {
          total: fileIds.length,
        }));
      };

      const onError = () => {
        setIsFetching(false);
        reject();
        AlertManager.error(getMessage(messageCodes.GENERAL_ERROR));
      };

      try {
        const payload = {
          dataroomId: dataroom.id,
          fileIds,
          onFinish,
          onError,
        };

        const redactionRepository = container.get<RedactionRepository>(RedactionRepository);

        await redactionRepository.removePendingOnSelectedFiles(payload);
      } catch (error) {
        reject();
        container.get(DataroomErrorHandler)
          .handleError(error);
      }
    })
  );

  return {
    isFetching,
    removeAllPendingRedactions,
    removeAllAppliedRedactions,
    removePendingOnSelectedFiles,
  };
}
