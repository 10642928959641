import { Dependencies } from 'constitute';
import { Notification, RpcSuccess } from '@dealroadshow/json-rpc-dispatcher';
import { Subscription } from '@dealroadshow/socket-frontend-sdk';
import Request from '@/Framework/api/Rpc/Request';
import JsonRpcDispatcherFactory from '@/dataroom/application/DI/Rpc/HttpDispatcher';
import SocketClientFactory from '@/dataroom/application/DI/Socket/Client';
import { EnabledRedactions } from '@/dataroom/domain/vo/redaction/EnabledRedactions';

@Dependencies(JsonRpcDispatcherFactory, SocketClientFactory)
class DownloadRepository {
  constructor(
    private rpc: typeof JsonRpcDispatcherFactory,
    private socketClient: typeof SocketClientFactory,
  ) {}

  checkDownloadAccess = async (payload: {
    dataroomId: number,
    fileId: number,
  }): Promise<void> => {
    const request = new Request('dataroom.download.check_access', payload);
    const response = await this.rpc().call<RpcSuccess>(request);

    return response.getResult();
  };

  requestDownload = async (payload: {
    uuid: string,
    dataroomId: number,
    quickFilterName?: string,
    filters?: { [key: string]: string | number },
    folderId?: number,
    folders?: { id: number }[],
    files?: { id: number }[],
    filesystemArea: string,
    onFinish: (hash: string) => void,
    onError: () => void,
  }): Promise<void> => {
    const {
      uuid,
      quickFilterName,
      onFinish,
      onError,
      ...otherPayload
    } = payload;

    const subscribeReq = new Request('dataroom.archive_request.listen', { uuid });

    const subscription = await this.socketClient().subscribe<Subscription>(subscribeReq);

    subscription
      .on('archive_request.completed', (notification) => {
        onFinish(notification.params.payload.hash);
      })
      .on('archive_request.failed', onError);

    try {
      const request = new Request(`dataroom.archive_request.${ quickFilterName || 'create_selected' }`, {
        uuid,
        ...otherPayload,
      });
      const response = await this.rpc().call<RpcSuccess>(request);

      return response.getResult();
    } catch (error) {
      subscription.cancel();
      throw error;
    }
  };

  downloadArchive = async (payload: {
    uuid: string,
    dataroomId: number,
    hash: string,
    truncationMode: string,
    onPendingManual: () => void,
    onHasLongPath: () => void,
    onError: () => void,
    onFinish: (url: string) => void,
    redaction: EnabledRedactions,
  }): Promise<void> => {
    const {
      uuid,
      onPendingManual,
      onHasLongPath,
      onFinish,
      onError,
      redaction,
      ...otherPayload
    } = payload;

    const subscribeReq = new Request('dataroom.download.archive.listen', { uuid });

    const subscription = await this.socketClient().subscribe<Subscription>(subscribeReq);

    subscription
      .on('archive.completed', (notification: Notification) => {
        onFinish(notification.params.payload.url);
      })
      .on('archive.failed', onError)
      .on('archive.pending_manual', () => {
        onPendingManual();
        subscription.cancel();
      })
      .on('archive.hasLongPath', () => {
        onHasLongPath();
        subscription.cancel();
      });

    const originalSuffix = redaction === EnabledRedactions.WithoutRedactions ? '.original' : '';

    const request = new Request(`dataroom.download.archive${ originalSuffix }`, {
      uuid,
      ...otherPayload,
    });

    const response = await this.rpc().call<RpcSuccess>(request);

    return response.getResult();
  };

  isExportDirectDownloadAvailable = async (payload: {
    dataroomId: number,
    hash: string,
  }): Promise<boolean> => {
    const request = new Request('dataroom.export.is_available', payload);
    const response = await this.rpc().call<RpcSuccess>(request);
    return response.getResult();
  };

  isArchiveDirectDownloadAvailable = async (payload: {
    dataroomId: number,
    hash: string,
  }): Promise<boolean> => {
    const request = new Request('dataroom.archive.is_available', payload);
    const response = await this.rpc().call<RpcSuccess>(request);
    return response.getResult();
  };

  generateDefaultArchiveName = async (payload: {
    dataroomId: number,
  }): Promise<{ name: string }> => {
    const request = new Request('dataroom.download.generate_default_name', payload);
    const response = await this.rpc().call<RpcSuccess>(request);
    return response.getResult().payload;
  };
}

export default DownloadRepository;
