const ABOUT = '/about';
const CAREERS = '/careers';
const SOLUTIONS = '/our-solutions';
const PRICING = '/pricing';
const BOOK_DEMO_ALLOCATE = '/our-solutions/book-demo-allocate';
const CONTACT = '/contact-us';
const FEATURES = '/market-data-features';
const ISSUERS_LIST = '/list-of-global-fixed-income-issuers';
const SPONSORS_LIST = '/list-of-global-fixed-income-corporate-sponsors';
const LEAGUE_TABLE = '/fixed-income-underwriter-rating-agency-league-table';
const POLICIES_TERMS = '/terms-of-use';
const POLICIES_PRIVACY = '/privacy-policy';
const POLICIES_SECURITY = '/security-policy';
const POLICIES_COMPLIANCE = '/compliance';
const SUBSCRIPTIONS = '/subscriptions';
const OPEN_MARKET = '/openmarket';

export default {
  BASE: '/',
  DEAL: '/deal',
  ABOUT,
  CONTACT,
  CAREERS,
  FEATURES,
  ISSUER: '/issuer',
  SPONSOR: '/sponsor',
  POLICIES: '/policies',
  CORPORATE_BONDSCREENER: '/bond-screener/corporate-bonds',
  CORPORATE_BONDSCREENER_DEAL: '/bond-screener/corporate-bonds/deal',
  EXTERNAL_LINK: '/external-link',
  BONDS: '/bonds',
  NOTIFICATION_UNSUBSCRIBE: '/notification/unsubscribe',
  NOT_FOUND: '/404',
  UNDERWRITER_RATING_AGENCY_LEAGUE_TABLE: '/:leagueType(underwriter|rating-agency)-:viewId-:companyName?',
  LEAGUE_TABLE,
  LEAGUE_TABLE_ALL_COMPANIES: '/fixed-income-underwriter-rating-agency-league-table/all',
  LEAGUE_TABLE_UNDERWRITERS: '/fixed-income-underwriter-rating-agency-league-table/underwriters',
  LEAGUE_TABLE_RATING_AGENCIES: '/fixed-income-underwriter-rating-agency-league-table/rating-agencies',
  OPEN_MARKET,

  // new routes from seo epic
  SOLUTIONS,
  PRICING,
  BOOK_DEMO_ALLOCATE,
  POLICIES_TERMS: '/terms-of-use',
  POLICIES_PRIVACY: '/privacy-policy',
  POLICIES_SECURITY: '/security-policy',
  POLICIES_COMPLIANCE: '/compliance',
  BONDS_BWICS: '/bwics',
  BONDS_INVENTORIES: '/inventories',
  BONDS_INVENTORIES_ABS: '/inventories-abs', // abs = asset class selected by default on bwics/inventories page
  TRACE: '/trace',
  BONDS_SUMMARY_BASE: '/dealer-summary',
  BONDS_BWICS_SUMMARY: '/dealer-summary-bwics',
  BONDS_INVENTORIES_SUMMARY: '/dealer-summary-inventories',
  PRODUCT_US_ABS_EE: '/us-abs-loan-level-data-on-edgar',
  PRODUCT_US_ABS_PIPELINE: '/us-abs-new-issue-pipeline',
  MARKET_OVERVIEW_ALL: '/all-abs-market-bond-issuance-overview',
  MARKET_OVERVIEW_HYC: '/high-yield-corporate-bond-issuance-overview',
  MARKET_OVERVIEW_IGC: '/investment-grade-corporate-bond-issuance-overview',
  MARKET_OVERVIEW_CONSUMER: '/abs-exclos-cmbs-rmbs-market-bond-issuance-overview',
  ABS_BONDSCREENER: '/bond-screener/asset-backed-securities',
  ABS_BONDSCREENER_DEAL: '/bond-screener/asset-backed-securities/deal',
  STRATS: '/us-abs-loan-level-data-on-edgar/stratmaker',
  NRSRO_RESEARCH: '/fixed-income-research',
  NRSRO_RESEARCH_REPORTS: '/fixed-income-research/reports',
  ISSUERS_LIST,
  SPONSORS_LIST,
  SUBSCRIPTIONS,
  PORTFOLIO_MONITOR: '/portfolio-monitor',
  CREDIT_FLOW_ARTICLE: '/post',
  CREDIT_FLOW_ARTICLE_LIST_ALL: '/commentary-deal-flow-all',
  CREDIT_FLOW_STATS_LIST_ALL: '/new-issue-stats-all',
  CREDIT_FLOW_STAT: '/stat',

  // suffixes to create url for profile pages
  INDUSTRY_SUFFIX: 'bond-issuance-overview',
  SECTOR_SUFFIX: 'abs-bond-issuance-overview',
  // end suffixes to create url for profile pages

  OLD_ROUTES: {
    INDUSTRY: '/industry',
    SECTOR: '/sector',
    SOLUTIONS: '/solutions',
    BOOK_DEMO_ALLOCATE: '/solutions/book-demo-allocate',
    POLICIES_TERMS: '/policies/terms',
    POLICIES_PRIVACY: '/policies/privacy',
    POLICIES_SECURITY: '/policies/security',
    POLICIES_COMPLIANCE: '/policies/compliance',
    PRODUCT_US_ABS_PIPELINE: '/product/us/abs/pipeline',
    PRODUCT_US_ABS_EE: '/product/us/abs/ee',
    MARKET_OVERVIEW_ALL: '/marketoverviewall',
    MARKET_OVERVIEW_CONSUMER: '/marketoverviewconsumer',
    ABS_BONDSCREENER: '/bondscreener',
    DEALSTREAM: '/dealstream',
    BONDS_BWIC: '/bonds/bwic',
    BONDS_INVENTORY: '/bonds/inventory',
    NRSRO_RESEARCH: '/nrsro/research',
    SCORECARD: '/scorecard',
    STRATS: '/strats',
    CONTACT: '/contact',
    FEATURES: '/features',
  },
  POLICY_PAGES: [
    POLICIES_COMPLIANCE,
    POLICIES_PRIVACY,
    POLICIES_SECURITY,
    POLICIES_TERMS,
  ],
  STATIC_PAGES_LIST: [
    ABOUT,
    CAREERS,
    SOLUTIONS,
    BOOK_DEMO_ALLOCATE,
    CONTACT,
    FEATURES,
    ISSUERS_LIST,
    SPONSORS_LIST,
    SUBSCRIPTIONS,
    POLICIES_COMPLIANCE,
    POLICIES_PRIVACY,
    POLICIES_SECURITY,
    POLICIES_TERMS,
    PRICING,
  ],
  /**
   * If you don't want ScrollToTop on the page, put URL for that page here here.
   * For example: SPONSOR.
   */
  PAGES_WITHOUT_SCROLL_TO_TOP: [
    LEAGUE_TABLE,
  ],
};
