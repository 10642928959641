import React, { createContext, useContext, useState, useEffect } from 'react';
import { useDIContext } from '@/Framework/DI/DIContext';
import { useDataroomTenantContext } from './DataroomTenantContext';
import { useDataroomTwoFactorAuthenticationContext } from '@dataroom/authentication';
import DataroomRepository from '@/dataroom/infrastructure/repository/DataroomRepository';
import dataroomUrl from '@/dataroom/infrastructure/dataroomUrl';
import DataroomErrorHandler from '@/dataroom/application/ErrorHandler';
import { IDataroom } from '@/dataroom/domain/vo/Dataroom';

const useDataroom = () => {
  const { container } = useDIContext();
  const { tenant } = useDataroomTenantContext();
  const { entityName, twoFactorAccessError } = useDataroomTwoFactorAuthenticationContext();

  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [dataroom, setDataroom] = useState<IDataroom>(null);

  useEffect(() => {
    if (twoFactorAccessError && entityName) {
      // TODO: Should use current location for callbackUrl.
      window.location.href = dataroomUrl(tenant).getDataroomListTwoFactorRedirectUrl(entityName, window.location.href);
    }
  }, [twoFactorAccessError, entityName]);

  const getDataroomById = async (dataroomId: number) => {
    setIsFetching(true);

    try {
      const dataroomRepository = container.get<DataroomRepository>(DataroomRepository);
      const dataroom = await dataroomRepository.getDataroomById({ dataroomId });
      setDataroom(dataroom);
    } catch (error) {
      container.get(DataroomErrorHandler).handleError(error);
    } finally {
      setIsFetching(false);
    }
  };

  const getDataroomByName = async (dataroomName: string) => {
    setIsFetching(true);

    try {
      const dataroomRepository = container.get<DataroomRepository>(DataroomRepository);
      const dataroom = await dataroomRepository.getDataroomByName({ dataroomName });

      setDataroom(dataroom);
    } catch (error) {
      container.get(DataroomErrorHandler).handleError(error, null, tenant);
    } finally {
      setIsFetching(false);
    }
  };

  return {
    isFetching,
    dataroom,
    getDataroomByName,
    getDataroomById,
  };
};

export type DataroomContextType = ReturnType<typeof useDataroom>;

export const DataroomContext = createContext<DataroomContextType>(null);

export const useDataroomContext = () => {
  const context = useContext(DataroomContext);
  if (!context) {
    throw new Error('useDataroomContext must be used within a DataroomContext');
  }
  return context;
};

interface IProps {
  children: React.ReactNode,
}

const DataroomContextProvider = ({ children }: IProps) => (
  <DataroomContext.Provider value={ useDataroom() }>
    { children }
  </DataroomContext.Provider>
);

export default DataroomContextProvider;
