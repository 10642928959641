import { isScreenS } from '@dealroadshow/uikit/core/styles/screen/screen';
import { canRedactFile, canRedactByKeyword } from '@/dataroom/domain/filesystemPermissions';
import { useCurrentUserContext } from '@/dataroom/application/CurrentUserContext';
import { IFilesystemListItem } from '@/dataroom/domain/vo/collection/FilesystemListItem';
import useIsMobile from '@/Framework/hooks/useIsMobile';

export const useRedactionPermissions = (selected: IFilesystemListItem[]) => {
  const { currentUser } = useCurrentUserContext();
  const isMobile = useIsMobile(isScreenS);

  return {
    userCanRedactFile: !isMobile && canRedactFile(currentUser, selected[0], selected.length),
    userCanRedactByKeyword: canRedactByKeyword(currentUser, selected),
  };
};
