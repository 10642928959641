import { IFilesystemListItem } from '@/dataroom/domain/vo/collection/FilesystemListItem';

export type Items = Pick<IFilesystemListItem, 'id' | 'type'>[];

export const getItemsPayload = (items: Items) => items.map(({
  id,
  type,
}) => ({
  id,
  type,
}));
