import { adapters } from '@dealroadshow/json-rpc-dispatcher';
import Container from '@/Framework/DI/Container';
import { PageRenderingState } from '@/Framework/DI';
import ClientReqData from '@/Framework/Router/Next/headers/ClientReqData';

class AdapterFactory {
  constructor(private url: string, private container: Container) {}

  getAdapter(options?: { otelPropagation: boolean }) {
    const { ServerSideDataFetching } = PageRenderingState;
    const initialHeaders = new Headers();
    const pageRenderingState = this.container.get(PageRenderingState);
    const headers = pageRenderingState === ServerSideDataFetching
      ? this.container.get<ClientReqData>(ClientReqData).ssrHeaders
      : initialHeaders;

    return new adapters.Fetch(
      this.url,
      {
        headers,
        otelPropagation: options?.otelPropagation,
      });
  }
}

export default AdapterFactory;
