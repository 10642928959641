import { domain } from '@/Framework/config/domain';
import isServer from '@/Framework/Router/Next/isServer';

// We don't need socket connection on the server side
// We force socket connection to dummy localhost in case the variables sre not yet replaced.
// This hack can be removed after we clean up our codebase from getContainer() calls.
// The socket connection is created during the build time, because we do instantate it in the root scope
// with the createAsyncCollection factory
const socketUrl = isServer() || !domain.protocol.includes('http')
  ? 'http://disabled.invalid'
  : `${ window.location.origin }/proxy/socket`;

const socketConfig = {
  url: socketUrl,
};

export default socketConfig;
