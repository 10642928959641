import React, { useEffect } from 'react';
import cn from 'classnames';
import CopyToClipboardButton from '@/Framework/UI/Atoms/Button/CopyToClipboardButton';
import { IFilesystemListItem } from '@/dataroom/domain/vo/collection/FilesystemListItem';
import { IFolderTree } from '@/dataroom/domain/vo/filesystem/FolderTree';
import { addHttpToUrl } from '@/Framework/url/helpers/transformUrl';
import { getMessage } from '@/Framework/Message/Mapper/getMessage';
import { messageCodes } from '@/Framework/Message/messages';
import { AlertManager } from '@dealroadshow/uikit/core/components/Alert';
import { isFolder, isFile, filesystemItemType } from '@/dataroom/domain/filesystem';
import { useFolderInfoContext } from '@/dataroom/application/FolderInfoContext';
import { useFileInfoContext } from '@/dataroom/application/FileInfoContext';
import Spinner from '@dealroadshow/uikit/core/components/Loader/Spinner';
import ActionButton from '@/Framework/UI/Atoms/Button/ActionButton';
import { variantTypes } from '@/Framework/UI/Atoms/Button/ActionButton/constants';
import styles from './copyLinkButton.scss';
import IconLink from '@dealroadshow/uikit/core/components/Icon/IconLink';
import IconCheck from '@dealroadshow/uikit/core/components/Icon/IconCheck';

interface IProps {
  item: (IFilesystemListItem | IFolderTree) & { href?: string, directLink?: string },
  dataTest: string,
  className?: string,
  isMenuVisible?: boolean,
  activeId?: number,
}

const CopyLinkButton = ({
  item,
  dataTest,
  className,
  isMenuVisible,
  activeId,
}: IProps) => {
  const {
    getFolderInfo,
    folderInfo,
    resetFolderInfo,
  } = useFolderInfoContext();
  const {
    getFileInfo,
    fileInfo,
    resetFileInfo,
  } = useFileInfoContext();

  useEffect(() => {
    if (isMenuVisible) {
      isFolder(item) && getFolderInfo(item.id);
      isFile(item) && getFileInfo(item.id);
    }

    return () => {
      resetFolderInfo();
      resetFileInfo();
    };
  }, [isMenuVisible, activeId]);

  const handleCopy = () => {
    AlertManager.success(
      <>
        <IconCheck className={ styles.iconCheck } />
        { getMessage(messageCodes.DATAROOM_LINK_COPIED) }
      </>,
    );
  };

  const linkText = {
    [filesystemItemType.URL]: 'Copy Link',
    [filesystemItemType.FILE]: 'Copy File Link',
    [filesystemItemType.FOLDER]: 'Copy Folder Link',
  };

  const content = item.href ? addHttpToUrl(item.href) : folderInfo?.directLink || fileInfo?.directLink;

  return content ? (
    <CopyToClipboardButton
      content={ content }
      handleCopy={ handleCopy }
      copiedMessageDelay={ 0 }
      className={ cn(className, styles.buttonWrapper) }
      customStyles={ styles.copyButton }
      labelBeforeCopy={ linkText[item.type] }
      labelAfterCopy={ linkText[item.type] }
      dataTest={ dataTest }
      icon={ IconLink }
    />
  ) : (
    <ActionButton
      className={ cn(className, styles.buttonWrapper) }
      buttonClassName={ styles.button }
      title={ (
        <div className={ styles.buttonInner }>
          <div className={ styles.buttonTitle }>Generating...</div>
          <Spinner size="small" />
        </div>
      ) }
      icon={ IconLink }
      isDisabled
      dataTest={ dataTest }
      variant={ variantTypes.textWithIcon }
    />
  );
};

export default CopyLinkButton;
