import { Dependencies } from 'constitute';
import Request from '@/Framework/api/Rpc/Request';
import RpcDispatcher from '@/dmPortal/application/DI/Rpc/HttpDispatcher';
import { Subscription } from '@dealroadshow/socket-frontend-sdk';
import SocketClient from '@/dealroadshow/application/DI/Socket/Client';
import { RoadshowRole } from '@/dealroadshow/domain/vo/RoadshowRole';
import { withCache } from '@/Framework/withCache';
import { IRoadshowSubmitData } from './IRoadshowSubmitData';
import { IRoadshowData } from './IRoadshowData';
import { callbackWrapper } from '@/Framework/DI/Providers/Socket/helpers/callbackWrapper';
import { IAudioVideoRoadshowSettings, IAudioVideoRoadshowSettingsPayload } from '@/dealroadshow/domain/vo/roadshow/IAudioVideoRoadshow';

@Dependencies(RpcDispatcher, SocketClient)
class RoadshowRepository {
  private rpc: typeof RpcDispatcher;

  private socket: typeof SocketClient;

  private roadshowSubscription: typeof Subscription;

  protected constructor(rpcDispatcher, socketClient) {
    this.rpc = rpcDispatcher;
    this.socket = socketClient;
    this.roadshowSubscription = null;
  }

  /**
   * Get roadshows list
   *
   * @param {Object} filters
   */
  async getList(filters): Promise<any> {
    const defaultFilters = {
      sortBy: 'name',
      sortOrder: 'asc',
    };

    if (filters.perPage === 'all') {
      const { perPage, page, ...otherFilters } = filters;
      filters = otherFilters;
    }

    filters = { ...defaultFilters, ...filters };
    let req = new Request('roadshow.list.manager', filters);
    let response = await this.rpc.call(req);

    return response.getResult().payload;
  }

  /**
   * Get retail roadshows list
   *
   * @param {Object} payload
   */
  async getRetailList(payload): Promise<void> {
    let req = new Request('roadshow.list.retail', payload);
    let response = await this.rpc.call(req);

    return response.getResult().payload;
  }

  /**
   * Get roadshows list
   *
   */
  async getAdminList(filter): Promise<any> {
    let req = new Request('admin.roadshow.get_list', filter);
    let response = await this.rpc.call(req);

    return response.getResult().payload;
  }

  /**
   * Toggle lock(unlock), hidden(show) and deleted(revert) action
   *
   */
  async toggleAdminListActions(toggleParams: { id: string, type: string, value: boolean }): Promise<any> {
    let req = new Request('admin.roadshow.toggle_actions', toggleParams);
    let response = await this.rpc.call(req);

    return response.getResult().payload;
  }

  async getRoadshowById(roadshowId: string): Promise<IRoadshowData> {
    let req = new Request('roadshow.get_by_id', { roadshowId });
    let response = await this.rpc.call(req);

    return response.getResult().payload;
  }

  async getRoadshowSettingsById(roadshowId: string): Promise<any> {
    let req = new Request('roadshow.get_settings_by_id', { roadshowId });
    let response = await this.rpc.call(req);

    return response.getResult().payload;
  }

  /**
   * Get presentable roadshow by id
   *
   */
  async getPresentableRoadshowById(roadshowId: string): Promise<any> {
    let req = new Request('roadshow.get_presentable_by_id', { roadshowId });
    let response = await this.rpc.call(req);

    return response.getResult().payload;
  }

  /**
   * Get presentable roadshow by id
   *
   */
  async getPreviewPresentableRoadshowById(roadshowId: string): Promise<any> {
    let req = new Request('roadshow.get_preview_presentable_by_id', { roadshowId });
    let response = await this.rpc.call(req);

    return response.getResult().payload;
  }

  /**
   * Get presentable retail roadshow by id
   *
   */
  async getPresentableRetailRoadshowById(roadshowId: string): Promise<any> {
    let req = new Request('roadshow.get_presentable_retail_by_id', { roadshowId });
    let response = await this.rpc.call(req);

    return response.getResult().payload;
  }

  /**
   * Get presentable roadshow by entry code
   *
   */
  async getPresentableRoadshowByEntryCode(entryCode: string): Promise<any> {
    let req = new Request('roadshow.get_presentable_by_entry_code', { entryCode });
    let response = await this.rpc.call(req);

    return response.getResult().payload;
  }

  async getAdminDealSettings(roadshowId: string): Promise<any> {
    let req = new Request('admin.roadshow.get_deal_settings', { roadshowId });
    let response = await this.rpc.call(req);

    return response.getResult().payload;
  }

  async getAdminRoadshowMapping(roadshowId: string): Promise<any> {
    let req = new Request('admin.roadshow.get_roadshow_mapping', { roadshowId });
    let response = await this.rpc.call(req);

    return response.getResult().payload;
  }

  /**
   * @param {Object} dealSettings
   */
  async updateAdminDealSettings(dealSettings): Promise<any> {
    let req = new Request('admin.roadshow.set_deal_settings', dealSettings);
    let response = await this.rpc.call(req);

    return response.getResult().payload;
  }

  /**
   * @param {Object} roadshowMapping
   */
  async updateRoadshowMapping(roadshowMapping): Promise<any> {
    let req = new Request('admin.roadshow.set_roadshow_mapping', roadshowMapping);
    let response = await this.rpc.call(req);

    return response.getResult().payload;
  }

  /**
   * @param {Object} filters
   */
  async getAdminDealFiles(filters): Promise<any> {
    let req = new Request('admin.roadshow.get_deal_files_list', filters);
    let response = await this.rpc.call(req);

    return response.getResult().payload;
  }

  async getAdminDealSlides(roadshowId: string): Promise<any> {
    let req = new Request('admin.roadshow.get_deal_slides', { roadshowId });
    let response = await this.rpc.call(req);

    return response.getResult().payload;
  }

  /**
   * @param {Object} dealSlides
   */
  async updateAdminDealSlides(dealSlides): Promise<any> {
    let req = new Request('admin.roadshow.set_deal_slides', dealSlides);
    let response = await this.rpc.call(req);

    return response.getResult().payload;
  }

  /**
   * @param {Object} dealSlides
   */
  async moveAdminDealSlides(dealSlides): Promise<any> {
    let req = new Request('admin.roadshow.move_deal_slides', dealSlides);
    let response = await this.rpc.call(req);

    return response.getResult().payload;
  }

  async getAdminAudioVideo(roadshowId: string): Promise<IAudioVideoRoadshowSettings> {
    const req = new Request('admin.roadshow.get_deal_presentation_media', { roadshowId });
    const response = await this.rpc.call(req);

    return response.getResult().payload;
  }

  async updateAdminAudioVideo(audioVideo: IAudioVideoRoadshowSettingsPayload): Promise<IAudioVideoRoadshowSettings> {
    const req = new Request('admin.roadshow.set_deal_presentation_media', audioVideo);
    const response = await this.rpc.call(req);

    return response.getResult().payload;
  }

  /**
   * Get public access url
   *
   * @param {String} url
   * @param {?String} cdnType
   */
  async getTemporaryAssetUrl(url: string, cdnType = null): Promise<any> {
    if (!url) {
      return null;
    }
    let req = new Request('roadshow.get_temporary_url', { url, cdnType });
    let response = await this.rpc.call(req);

    return response.getResult().payload;
  }

  /**
   * @param {Object} filters
   */
  async getAdminAnalytics(filters): Promise<any> {
    let req = new Request('admin.roadshow.analytics.get_investor_logins_history', filters);
    let response = await this.rpc.call(req);

    return response.getResult().payload;
  }

  /**
   * @param {Object} filters
   */
  async getAdminLoginLimits(filters): Promise<any> {
    let req = new Request('admin.roadshow.get_login_limits', filters);
    let response = await this.rpc.call(req);

    return response.getResult().payload;
  }

  /**
   * @param {Object} filters
   */
  async resetAdminLoginLimits(filters): Promise<any> {
    let req = new Request('admin.roadshow.reset_investor_login_limits', filters);
    let response = await this.rpc.call(req);

    return response.getResult().payload;
  }

  /**
   * @param {Object} filters
   */
  async resetAdminInvestorLoginsCountByEntryCode(filters): Promise<any> {
    let req = new Request('admin.roadshow.reset_investor_logins_count_by_entry_code', filters);
    let response = await this.rpc.call(req);

    return response.getResult().payload;
  }

  async getAdminCustomModal(roadshowId: string): Promise<any> {
    let req = new Request('admin.roadshow.get_custom_modal', { roadshowId });
    let response = await this.rpc.call(req);

    return response.getResult().payload;
  }

  /**
   * @param {Object} data
   */
  async saveAdminCustomModal(data): Promise<any> {
    let req = new Request('admin.roadshow.save_custom_modal', data);
    let response = await this.rpc.call(req);

    return response.getResult().payload;
  }

  /**
   * @param {Object} data
   */
  async toggleDeleteRelatedRoadshow(data): Promise<any> {
    let req = new Request('admin.roadshow.toggle_related_roadshow_actions', data);
    let response = await this.rpc.call(req);

    return response.getResult().payload;
  }

  /**
   * @param {Object} data
   */
  async roadshowAdminClearStats(data): Promise<any> {
    let req = new Request('admin.roadshow.analytics.clear_stats', data);
    let response = await this.rpc.call(req);

    return response.getResult().payload;
  }

  /**
   * Toggle roadshow published state
   *
   */
  async togglePublished(roadshowId: string, published: boolean): Promise<void> {
    let req = new Request('roadshow.toggle_published', { roadshowId, published });
    let response = await this.rpc.call(req);

    return response.getResult().payload;
  }

  async create(data: IRoadshowSubmitData): Promise<any> {
    let req = new Request('roadshow.create', data);
    let response = await this.rpc.call(req);

    return response.getResult().payload;
  }

  async update(data: IRoadshowSubmitData): Promise<any> {
    let req = new Request('roadshow.update', data);
    let response = await this.rpc.call(req);

    return response.getResult().payload;
  }

  async getDialInCode(payload: { count: number }): Promise<any> {
    const req = new Request('roadshow.get_generated_dial_in_codes', payload);
    const response = await this.rpc.call(req);

    return response.getResult().payload;
  }

  @withCache
  async getDialInPhones(params?): Promise<any> {
    let req = new Request('roadshow.get_dial_in_phones', params);
    let response = await this.rpc.call(req);

    return response.getResult().payload;
  }

  /**
   * Get transactionTypeId by roadshowId
   *
   * @param {Object} payload
   */
  async getTransactionTypeByRoadshowId(payload): Promise<any> {
    let req = new Request('roadshow.get_transaction_type_id_by_roadshow_id', payload);
    let response = await this.rpc.call(req);

    return response.getResult().payload;
  }

  /**
   * Check if admin have analytics only permissions by roadshowId
   *
   * @param {Object} payload
   */
  async isAdminAnalyticsOnlyByRoadshowId(payload): Promise<any> {
    let req = new Request('roadshow.analytics.deal.is_manager_analytics_only_by_roadshow_id', payload);
    let response = await this.rpc.call(req);

    return response.getResult().payload;
  }

  /**
   * Update the dashboard link id
   *
   */
  async updateDashboardLink(payload: { roadshowId: string, evercallChatId: string }): Promise<any> {
    let req = new Request('roadshow.update_dashboard_link', payload);
    let response = await this.rpc.call(req);

    return response.getResult().payload;
  }

  /**
   * Save company name or underwriter
   *
   * @param {Object} payload
   */
  async saveAccount(payload): Promise<any> {
    let req = new Request('dm_portal.request_to_create_account', payload);
    let response = await this.rpc.call(req);

    return response.getResult().payload;
  }

  async getViewerCustomSettings(payload): Promise<any> {
    let req = new Request('roadshow.get_viewer_custom_settings', payload);
    let response = await this.rpc.call(req);

    return response.getResult().payload;
  }

  async getEvercallTemplateSetting(payload): Promise<any> {
    let req = new Request('admin.roadshow.get_evercall_template_settings', payload);
    let response = await this.rpc.call(req);

    return response.getResult().payload;
  }

  async getCurrentRole(roadshowId?: string): Promise<{
    isAdmin: boolean,
    currentDealroadshowRole: RoadshowRole | null,
  }> {
    let req = new Request('roadshow.get_current_role', {
      roadshowId,
    });
    let response = await this.rpc.call(req);

    return response.getResult().payload;
  }

  /**
   * Check if can be started generating video replay
   */
  async canStartVideoReplayGeneration(roadshowId: string): Promise<'ok'> {
    const req = new Request('admin.roadshow.presentation_video.check_export_conditions', { roadshowId });
    const response = await this.rpc.call(req);

    return response.getResult().payload;
  }

  /**
   * Start generating video replay
   */
  async startVideoReplayGeneration(roadshowId: string): Promise<null> {
    const req = new Request('admin.roadshow.presentation_video.start_export', { roadshowId });
    const response = await this.rpc.call(req);

    return response.getResult().payload;
  }

  async canBeChangedVideoType(roadshowId: string): Promise<boolean> {
    let req = new Request('admin.roadshow.can_change_type_video_only', { roadshowId });
    let response = await this.rpc.call(req);

    return response.getResult().payload;
  }

  subscribeOnRoadshowUpdate = async ({
    roadshowId,
    onPresentationUpdate,
    onPresentationRestricted,
  }: {
    roadshowId: string,
    onPresentationUpdate: void,
    onPresentationRestricted: void,
  }) => {
    const METHOD = 'roadshow.viewer.sub_unsub';

    const PRESENTATION_UPDATED_EVENT_TYPE = 'roadshow.presentation_updated';
    const ROADSHOW_UNPUBLISHED_EVENT_TYPE = 'roadshow.unpublished';
    const ROADSHOW_PUBLIC_TYPE_CHANGED_EVENT_TYPE = 'roadshow.public_type_changed';

    const RESTRICTED_EVENTS = [ROADSHOW_UNPUBLISHED_EVENT_TYPE, ROADSHOW_PUBLIC_TYPE_CHANGED_EVENT_TYPE];

    this.roadshowSubscription = await this.socket.subscribe(new Request(METHOD, { roadshowId }));

    // @ts-ignore
    this.roadshowSubscription.on(PRESENTATION_UPDATED_EVENT_TYPE, callbackWrapper(onPresentationUpdate));
    // @ts-ignore
    RESTRICTED_EVENTS.forEach((event) => this.roadshowSubscription.on(event, onPresentationRestricted));
  };

  unsubscribeOnRoadshowUpdate = () => {
    if (this.roadshowSubscription instanceof Subscription) {
      this.roadshowSubscription.cancel();
    }
  };
}

export default RoadshowRepository;
