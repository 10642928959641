import { injectable, inject } from 'inversify';
import Request from '@/Framework/api/Rpc/Request';
import { withCache } from '@/Framework/withCache';
import RpcDispatcher from '@/dmPortal/application/DI/Rpc/HttpDispatcher';
import JsonRpcDispatcher, { RpcSuccess } from '@dealroadshow/json-rpc-dispatcher';
import { ITimezone } from '@/Framework/TimeZone/vo/Timezone';

@injectable()
class TimeZoneRepository {
  constructor(@inject(RpcDispatcher) protected readonly rpc: JsonRpcDispatcher) {}

  @withCache
  async getList(): Promise<ITimezone[]> {
    const request = new Request('dm_portal.queries.get_time_zones');
    const response = await this.rpc.call<RpcSuccess>(request);

    return response.getResult().payload;
  }
}

export default TimeZoneRepository;
