import React, { createContext, useContext, useRef } from 'react';
import { Span, context as OTelContext, trace, SpanOptions } from '@dealroadshow/jsbro/OTel/api';
import { useDIContext } from '@/Framework/DI/DIContext';

export type IUseOTelTraceContext = ReturnType<typeof useOTelTrace>;

export const OTelTraceContext = createContext<IUseOTelTraceContext>(null);

// sometimes there is no parent context, so we need to skip the check
export const useOTelTraceContext = (skipCheck = false): IUseOTelTraceContext => {
  const context = useContext<IUseOTelTraceContext>(OTelTraceContext);
  if (!skipCheck && !context) {
    throw new Error('useOTelTraceContext must be used within a OTelTraceContextProvider');
  }
  return context;
};

const useOTelTrace = (name: string, options?: SpanOptions) => {
  const tracer = trace.getTracer();
  const context = useOTelTraceContext(true);
  let ctx = OTelContext.active();
  if (context) {
    ctx = trace.setSpan(OTelContext.active(), context.span);
  }

  const span = tracer.startSpan(name, options, ctx);
  const spanRef = useRef<Span>(span);

  return {
    span: spanRef.current,
  };
};

export interface IProps {
  children: React.ReactNode,
  name?: string,
  option?: SpanOptions,
}

export const getName = (children: any) => {
  const getDisplayName = (element: React.ReactNode): string => {
    let defaultName = 'ReactNode';

    if (React.isValidElement(element)) {
      return (
        // @ts-ignore
        element.type?.displayName ||
        // @ts-ignore
        element.type?.name ||
        element.constructor?.name ||
        defaultName
      );
    }
    return defaultName;
  };

  if (Array.isArray(children)) {
    return children.map(getDisplayName)
      .join(', ');
  }
  return getDisplayName(children);
};

const OTelTraceContextProvider = ({
  children,
  name,
  option,
}: IProps) => {
  if (!name) {
    name = getName(children);
  }
  const contextValue = useOTelTrace(name, option);
  const DIContext = useDIContext();
  DIContext.container.bindContextValue(OTelTraceContext, contextValue, true);

  return (
    <OTelTraceContext.Provider value={ contextValue }>
      { children }
    </OTelTraceContext.Provider>
  );
};

export default OTelTraceContextProvider;
