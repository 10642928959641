import flatten from 'lodash/flatten';
import urlJoin from 'url-join';
import config from '@/Framework/config';
import baseUrl from '@/Framework/url/baseUrl';
import dmPortalUrl from '@/dmPortal/infrastructure/url/dmPortalUrl';
import usersUrl from '@/users/infrastructure/usersUrl';

export default {
  getUrl(uris: Array<string> | string = []) {
    if (!Array.isArray(uris)) {
      uris = [uris];
    }
    uris = flatten(uris).filter((uri) => !!uri);
    return urlJoin(`${ config.protocol }//${ config.tenant.tenantEvercall.hostname }`, ...uris);
  },

  getRpcUrl({ disableProxy = false }: { disableProxy?: boolean } = {}): string {
    return baseUrl.getRpcUrl({ disableProxy });
  },

  getPropagateCallbackUrl(url: string): string {
    return baseUrl.getRestUrl(
      `/propagate?callbackUrl=${ encodeURIComponent(url) }&tenant=${ config.tenant.tenantEvercall.code }`,
    );
  },

  getLoginUrl(): string {
    return usersUrl.getLoginUrl(
      this.getPropagateCallbackUrl(dmPortalUrl.getUrl('/evercall')),
      config.tenant.tenantEvercall.code,
    );
  },

  getBaseFilesUrl(...uris: (string | string)[]): string {
    return baseUrl.getApiUrl('evercall/files', ...uris);
  },
};
