import { useState } from 'react';
import ErrorCodeHelper from '@finsight/error-codes';
import { useDIContext } from '@/Framework/DI/DIContext';
import { getMessage } from '@/Framework/Message/Mapper/getMessage';
import { AlertManager } from '@dealroadshow/uikit/core/components/Alert';
import { messageCodes } from '@/Framework/Message/messages';
import FolderRepository from '@/dataroom/infrastructure/repository/FolderRepository';
import FileRepository from '@/dataroom/infrastructure/repository/FileRepository';
import LinkRepository from '@/dataroom/infrastructure/repository/LinkRepository';
import { useDataroomContext } from '@/dataroom/application/DataroomContext';
import { useFolderTreeContext } from '@/dataroom/application/folderTree/FolderTreeContext';
import { useDataroomExplorerContext } from '@/dataroom/ui/common/DataroomExplorer/DataroomExplorerContext';
import DataroomErrorHandler from '@/dataroom/application/ErrorHandler';
import { filesystemItemType } from '@/dataroom/domain/filesystem';
import { systemFolders } from '@/dataroom/domain/filesystemPermissions';
import { useCurrentUserContext } from '@/dataroom/application/CurrentUserContext';
import * as managePermissions from '@/dataroom/domain/managePermissions';
import { PermissionGroup } from '@/dataroom/domain/vo/types/PermissionGroup';
import { getLinkValidationErrorMessage } from '@/Framework/UI/Organisms/FinalForm/validators/validateLink';

const useEdit = () => {
  const { container } = useDIContext();
  const { dataroom } = useDataroomContext();
  const { updateCollection: updateDataroomExplorerCollection } = useDataroomExplorerContext();
  const { updateFolderTree } = useFolderTreeContext();
  const [isFetching, setIsFetching] = useState(false);
  const { currentUser } = useCurrentUserContext();
  const isAdmin = managePermissions.canUserManageOwnSettings(currentUser);
  const ownPermissionIds = currentUser?.permissionGroups.map((permission) => permission.id);

  const finishHandler = async (
    shouldUpdate: boolean,
    onSuccess?: () => void,
  ) => {
    setIsFetching(false);
    AlertManager.success(getMessage(messageCodes.DATAROOM_CHANGES_SUCCESSFULLY_SAVED));
    shouldUpdate && updateDataroomExplorerCollection();
    shouldUpdate && onSuccess && onSuccess();
  };

  const handleError = (name: string, type: string, error) => {
    if (error?.code === ErrorCodeHelper.getCodeByName('DATAROOM_FILESYSTEM_ELEMENT_RENAME_CONFLICT')) {
      if (name === systemFolders.emailUpload || name === systemFolders.audioUpload) {
        AlertManager.error(getMessage(messageCodes.DATAROOM_RESERVED_FOLDER_NAME, { name }));
      } else {
        AlertManager.error(getMessage(messageCodes.DATAROOM_FILE_FOLDER_NAME_ALREADY_EXIST, { type, name }));
      }
    } else {
      if (('getData' in error) && error.getData()?.violations?.href?.includes?.('Hyperlink is incorrect')) {
        setIsFetching(false);
        return { href: getLinkValidationErrorMessage('URL') };
      }
      container.get(DataroomErrorHandler).handleError(error);
    }

    setIsFetching(false);
    return null;
  };

  const getCustomPermissionGroups = (
    permissionGroups: Partial<{ [key in PermissionGroup]: number[] }>,
  ): {
    id: number,
    permission: PermissionGroup,
  }[] => Object.keys(permissionGroups).reduce((acc, permission) => {
    permissionGroups[permission].forEach((id) => {
      // Prevent editing self PGs
      if (isAdmin || !ownPermissionIds.includes(id)) {
        acc.push({ id, permission });
      }
    });
    return acc;
  }, []);

  const editFolder = async (
    formData: {
      folderId: number,
      folderName: string,
    },
    permissionGroups: Partial<{ [key in PermissionGroup]: number[] }>,
    shouldUpdate: boolean,
  ) => new Promise<{ code: number } | null>((resolve) => {
    const payload = {
      dataroomId: dataroom.id,
      folderId: formData.folderId,
      folderName: formData.folderName,
      customPermissionGroups: getCustomPermissionGroups(permissionGroups),
    };

    const onFinish = () => {
      resolve(null);
      finishHandler(shouldUpdate, updateFolderTree.bind(null, payload.folderId));
    };

    const onError = (error) => {
      resolve(error);
      setIsFetching(false);
      handleError(payload.folderName, filesystemItemType.FOLDER, error);
    };

    try {
      const folderRepository = container.get<FolderRepository>(FolderRepository);
      setIsFetching(true);
      folderRepository.edit({ ...payload, onFinish, onError });
    } catch (error) {
      onError(error);
    }
  });

  const editFile = async (
    formData: {
      fileId: number,
      fileName: string,
      fileExtension: string,
    },
    permissionGroups: Partial<{ [key in PermissionGroup]: number[] }>,
    shouldUpdate: boolean,
  ) => new Promise<{ code: number } | null>((resolve) => {
    const payload = {
      dataroomId: dataroom.id,
      fileId: formData.fileId,
      fileName: `${ formData.fileName }.${ formData.fileExtension }`,
      customPermissionGroups: getCustomPermissionGroups(permissionGroups),
    };

    const onFinish = () => {
      finishHandler(shouldUpdate);
      resolve(null);
    };

    const onError = (error) => {
      resolve(error);
      setIsFetching(false);
      handleError(payload.fileName?.split('.')[0], filesystemItemType.FILE, error);
    };

    try {
      const fileRepository = container.get<FileRepository>(FileRepository);
      setIsFetching(true);
      fileRepository.edit({ ...payload, onFinish, onError });
    } catch (error) {
      onError(error);
    }
  });

  const editLink = async (
    formData: {
      linkId: number,
      name: string,
      href: string,
    },
    permissionGroups: Partial<{ [key in PermissionGroup]: number[] }>,
    shouldUpdate: boolean,
  ) => new Promise<{ href: string } | null>((resolve) => {
    const payload = {
      dataroomId: dataroom.id,
      linkId: formData.linkId,
      name: formData.name,
      href: formData.href,
      customPermissionGroups: getCustomPermissionGroups(permissionGroups),
    };

    const onFinish = () => {
      resolve(null);
      finishHandler(shouldUpdate);
    };

    const onError = (error) => {
      setIsFetching(false);
      resolve(handleError(payload.name, filesystemItemType.FILE, error));
    };

    try {
      const linkRepository = container.get<LinkRepository>(LinkRepository);
      setIsFetching(true);
      linkRepository.edit({ ...payload, onFinish, onError }).then(null, (error) => {
        onError(error);
      });
    } catch (error) {
      onError(error);
    }
  });

  return {
    isFetching,
    editFolder,
    editFile,
    editLink,
  };
};

export default useEdit;
